const styles = {
  icon: {
    fontSize: 24,
    color: "primary.main",
    mr: 1,
  },
  line: {
    display: "flex",
    margin: "auto",
    mb: 2,
  },
  notInterested: {
    ml: 1,
  },
};

export default styles;
