import { grey } from "@mui/material/colors";

const styles = {
  form: {
    background: "white",
    padding: 4,
    borderRadius: 4,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 24px 48px",
    width: 320,
  },
  button: {
    width: "100%",
    marginTop: 2,
    fontSize: 12,
    fontWeight: 500,
  },
  poweredByLogo: {
    width: 50,
    mb: 6,
  },
  title: {
    marginBottom: 1,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 16,
    color: grey[600],
  },
};

export default styles;
