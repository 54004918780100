import { useAuth } from "@clerk/clerk-react";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logoImg from "assets/logos/logo-transparent.png";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import {
  BENTO_RESOURCES_GUIDE_LINK,
  LANDING_PAGE_LINK,
} from "constants/influencer";
import DiscoverSearchBar from "features/Influencer/Discover/Search";
import { isPage } from "utils/general";
import {
  DISPLAY_SCHEDULED_DIALOG,
  ON_BEHALF_OF_TOKEN,
  SCHEDULED_DATE_EMAIL,
} from "utils/localStorage";
import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import TopNavigationMobile from "./mobile";
import { Page, PageMenu } from "./schema";
import styles from "./styles";

function TopNavigation() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const onBehalfToken = sessionStorage.getItem(ON_BEHALF_OF_TOKEN);

  const { signOut } = useAuth();
  const navigate = useNavigate();

  const { clearOrganizationContext, currentUser, currentOrg } = useContext(
    OrganizationUserContext,
  );
  const { discoverTab } = useContext(DiscoverViewContext);
  const { navigationWidth } = usePageSize();

  const showMobileNavigation =
    isMobileScreen || (isMediumScreen && discoverTab !== null);

  const { clearBrandContext } = useContext(BrandsContext);

  const [profileAnchor, setProfileAnchor] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    isProfileAnchor: boolean,
  ) => {
    setProfileAnchor(isProfileAnchor);
    trackEvent("Settings Menu Dropdown Clicked");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trackPageNavigation = (route: string) => {
    if (route === Page.BENTO_GUIDE) {
      trackEvent("How To Use Bento Link Clicked");
    } else {
      trackEvent("Page Navigated", { route });
    }
    handleClose();
  };

  const handleLogOut = () => {
    trackEvent("Logout Button Clicked");
    if (onBehalfToken) {
      sessionStorage.removeItem(ON_BEHALF_OF_TOKEN);
      window.open("/admin", "_self");
    } else {
      signOut(() => navigate(`/sign-in`, { replace: true }));
    }

    // Reset all contexts.
    clearBrandContext();
    clearOrganizationContext();

    // Reset localStorage
    localStorage.removeItem(`${DISPLAY_SCHEDULED_DIALOG}-${currentOrg?.id}`);
    localStorage.removeItem(`${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`);
  };

  const goToLandingPage = () => {
    trackEvent("Landing Page Logo Clicked", {
      "Clicked From": "Navigation Bar",
    });
    window.open(LANDING_PAGE_LINK, "_blank");
  };

  const renderPage = (page: string) => {
    const isActive = isPage(page);
    return (
      <Link to={`/dashboard/${page}`}>
        <Button
          sx={[styles.menuButton, isActive ? styles.activeMenuButton : {}]}
          onClick={() => trackPageNavigation(page)}
        >
          {PageMenu[page]?.label}
        </Button>
      </Link>
    );
  };

  const profileMenu = (
    <Grid
      item
      container
      xs="auto"
      justifyContent="flex-end"
      alignItems="center"
    >
      <ButtonBase onClick={(e) => handleClick(e, true)}>
        <Avatar sx={styles.avatar}>
          {generateInitials(currentUser?.name || currentUser?.email || "")}
        </Avatar>{" "}
        <Box
          component="i"
          className="fa-regular fa-chevron-down"
          sx={styles.arrow}
        />
      </ButtonBase>
    </Grid>
  );

  const getPagesToDisplay = () => {
    let pages: string[] = [];
    if (!profileAnchor) {
      pages = [Page.RECOMMENDATIONS, Page.BRANDS, Page.INBOX, Page.TRACKING];
    }
    if (!showMobileNavigation || profileAnchor) {
      pages = [
        ...pages,
        Page.SETTINGS,
        Page.TEMPLATES,
        Page.PROFILE,
        Page.BENTO_GUIDE,
      ];
    }
    return pages;
  };

  return (
    <>
      {showMobileNavigation ? (
        <TopNavigationMobile
          width={navigationWidth}
          handleClick={handleClick}
          profileMenu={profileMenu}
        />
      ) : (
        <Grid
          sx={[
            styles.navigation,
            {
              width: navigationWidth,
            },
          ]}
          gap={1}
          justifyContent="space-between"
        >
          <Grid item xs="auto">
            <Grid container alignItems="center">
              <Box
                component="img"
                src={logoImg}
                sx={styles.topBarLogo}
                onClick={goToLandingPage}
              />
              {renderPage(Page.RECOMMENDATIONS)}
              {renderPage(Page.BRANDS)}
              {renderPage(Page.INBOX)}
              {renderPage(Page.TRACKING)}
            </Grid>
          </Grid>

          <Grid item xs>
            {isPage(routes.brands) && <DiscoverSearchBar />}
          </Grid>

          {profileMenu}
        </Grid>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {getPagesToDisplay()?.map((page) => (
          <Link
            to={
              page === Page.BENTO_GUIDE
                ? BENTO_RESOURCES_GUIDE_LINK
                : `/dashboard/${page}`
            }
            style={{ textDecoration: "none" }}
            key={`page-${page}`}
            target={page === Page.BENTO_GUIDE ? "_blank" : "_self"}
          >
            <MenuItem key={page} onClick={() => trackPageNavigation(page)}>
              <ListItemIcon sx={styles.icon}>
                <Box component="i" className={PageMenu[page]?.icon} />
              </ListItemIcon>
              <ListItemText>{PageMenu[page]?.label}</ListItemText>
            </MenuItem>
          </Link>
        ))}
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon sx={styles.icon}>
            <Box component="i" className="fa-solid fa-sign-out" />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default TopNavigation;
