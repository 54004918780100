import { Box, Button, Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import { getSource, trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import FAQ from "./FAQ";

const VAQuestions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);

  const { currentOrg } = useContext(OrganizationUserContext);
  const source = getSource();
  const { trackVaStep } = useTracking();

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const goToDashboard = () => {
    trackEvent("VA Onboarding - Go to Dashboard Clicked");
    navigate(`/${routes.dashboard}${location.search}`);
  };

  useEffect(() => {
    if (currentOrg?.id) {
      trackVaStep(location.pathname, source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <>
      <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
        All pending items are completed 💯
      </Typography>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center", mt: 0 }}>
        Important Information About What's Next
      </Typography>

      <Box sx={{ display: "block", margin: "auto", mb: 3 }}>
        <li>
          You will get a chance to ask all of your questions on the 1:1
          Onboarding Call
        </li>
        <li>
          After your Onboarding Call, your Virtual Assistant will begin pitching
          to brands.
        </li>
        <li>
          You are responsible for responding to and negotiating with brands that
          respond back from a pitch.
        </li>
        <li>
          Over time, Bento will use data and analytics to improve your pitch
          strategy and increase response rates.
        </li>
        <li>
          At any time, if Bento decides you are unfit for the program, you will
          receive a 100% refund.
        </li>
      </Box>

      <FAQ />

      <Grid item xs="auto" container columnGap={1} sx={{ mt: 4 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>

        <Grid item xs="auto">
          <Button disableElevation onClick={goToDashboard}>
            Go to Main App
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VAQuestions;
