import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { isValidHttpUrl } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface PortfolioFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
}

const PortfolioForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
}: PortfolioFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);
  const [portfolioValidation, setPortfolioValidation] = useState<
    string | undefined
  >();

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const handleSkip = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "portfolioForm",
    });

    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "next")}${
        location.search
      }`,
    );
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const handlePortfolioBlurred = (e: CustomEvent) => {
    const { value } = e.target;

    const sanitizedInput = value.trim();

    if (sanitizedInput && !isValidHttpUrl(sanitizedInput)) {
      setPortfolioValidation("Link must start with http:// or https://");
    } else {
      setPortfolioValidation(undefined);
    }
  };

  const handlePortfolioChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const sanitizedInput = value.trim();

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = sanitizedInput;
    setOnboardingForm(copy);

    if (isValidHttpUrl(sanitizedInput)) {
      setPortfolioValidation(undefined);
    }
  };

  return (
    <form onSubmit={handleNext}>
      {!isProfilePage && (
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-address-card"
          />
          Share Your Portfolio
        </Typography>
      )}

      <Grid item xs={12}>
        <FormHelperText sx={styles.helperText}>
          Portfolio / website URL
        </FormHelperText>
        <TextField
          sx={styles.textField}
          fullWidth
          name="portfolioUrl"
          placeholder="https://www.blog.com"
          onChange={handlePortfolioChange}
          onBlur={handlePortfolioBlurred}
          error={!!portfolioValidation}
          helperText={portfolioValidation}
          value={onboardingForm?.portfolioUrl || ""}
        />
      </Grid>

      {!isProfilePage && (
        <Grid
          container
          justifyContent="flex-start"
          columnGap={1}
          sx={{ mt: 2 }}
        >
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button onClick={handleSkip} color="secondary" disableElevation>
              Skip
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button disableElevation type="submit" variant="contained">
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default PortfolioForm;
