import Bowser from "bowser";

export const getNativeBrowserLink = () => {
  const dashboardLink = `${window.location.protocol}//${window.location.hostname}/`;
  const userAgent = navigator.userAgent.toLowerCase();
  const parserFromBowser = Bowser.getParser(userAgent);
  const phoneType = parserFromBowser?.getOSName();

  if (phoneType === "iOS") {
    return `x-safari-${dashboardLink}`;
  } else if (phoneType === "Android") {
    return `intent://${window.location.hostname}?redirected=true#Intent;scheme=https;package=com.android.chrome;end`;
  }
  return dashboardLink;
};

export const isWebView = () => {
  const rules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
    // old chrome android webview agent
    "Linux; U; Android",
  ];

  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");
  const userAgent = navigator.userAgent.toLowerCase();
  return !!userAgent.match(webviewRegExp);
};
