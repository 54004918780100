import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { StringMap } from "schemas/functions";
import { Plans } from "schemas/payments";
import { routes } from "schemas/routes";

import {
  CLOSE_VA_ANNOUNCEMENT,
  VA_LANDING_PAGE_LINK,
} from "constants/influencer";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
}

const VAAnnouncement = ({ close, setClose }: Props) => {
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const { subscription } = useContext(SubscriptionContext);
  const closeFromSessionStorage =
    sessionStorage.getItem(`${CLOSE_VA_ANNOUNCEMENT}-${currentOrg?.id}`) ===
    "true";

  const [loading, setLoading] = useState(false);

  const trackLearnMore = () => {
    trackEvent("VA Landing Page Clicked");
  };

  const handleUpgrade = async () => {
    setLoading(true);
    try {
      const data: StringMap = {
        plan: Plans.VA_YEARLY,
        pageSource: `/${routes.dashboard}`,
        source: "VA Announcement",
      };
      if (subscription?.subscription?.planName !== Plans.FREE) {
        data["flow"] = "subscription_update";
      }
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/checkout`,
        "POST",
        {},
        data,
      );
      window.open(res.session.url, "_self");
    } catch (error) {
      setAlert(
        <>
          Unable to create checkout session. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists
        </>,
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const closeBanner = () => {
    setClose(true);
    sessionStorage.setItem(
      `${CLOSE_VA_ANNOUNCEMENT}-${currentOrg?.id}`,
      "true",
    );
  };

  useEffect(() => {
    if (closeFromSessionStorage && !close) {
      setClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFromSessionStorage]);

  return close || closeFromSessionStorage ? (
    <></>
  ) : (
    <Grid container sx={styles.container} alignItems="center" gap={4}>
      <Tooltip title="Close Banner">
        <IconButton sx={styles.close} onClick={closeBanner}>
          <Box component="i" className="fa-regular fa-times" />
        </IconButton>
      </Tooltip>
      <Grid item xs={12} md>
        <Typography variant="h6" sx={{ mb: 2 }}>
          ❗❗Just Launched: Bento's <strong>Income Guaranteed</strong> Virtual
          Assistant!
        </Typography>

        <Typography>
          Your assistant will reach out to thousands of brands on your behalf.
          No more hunting for email contacts or drafting pitch emails - your
          focus will only be on responding to the brands that reach back out.
          We're offering a money-back guarantee, but spaces are limited. If you
          commit to 1 year of Bento's Virtual Assistant and don't earn back at
          least what you paid within 12 months, we'll refund you in full - no
          questions asked.{" "}
          <Link
            onClick={trackLearnMore}
            href={VA_LANDING_PAGE_LINK}
            target="_blank"
            sx={{ color: "info.main", textDecoration: "none" }}
          >
            <strong>Learn More</strong>
          </Link>
        </Typography>
      </Grid>

      <Box justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          disableElevation
          sx={{ backgroundColor: "info.main" }}
          onClick={handleUpgrade}
          loading={loading}
        >
          Upgrade Now{" "}
          <Box
            component="i"
            className="fa-regular fa-arrow-right"
            sx={{ ml: 1 }}
          />
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default VAAnnouncement;
