import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import {
  SocialMediaFields,
  SocialMediaPlatforms,
  isValidUsername,
  sanitizeInput,
} from "../AboutForm/helpers";
import { onboardingNavigate } from "../helper";
import styles from "../styles";

const PLATFORM_DEFAULT = "instagram";

interface EngagementFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
}

const EngagementForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
}: EngagementFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const [usernameValidation, setUsernameValidation] = useState<
    string | undefined
  >();

  const handlePlatformChange = (e: CustomEvent) => {
    const { value } = e.target;
    const previousPlatform =
      onboardingForm.primarySocialMediaPlatform || PLATFORM_DEFAULT;
    const previousValue = onboardingForm[`${previousPlatform}Url`];
    if (previousValue) {
      onboardingForm[`${previousPlatform}Url`] = undefined;
    }
    const copy = makeDeepCopy(onboardingForm);
    copy.primarySocialMediaPlatform = value;
    if (previousValue) {
      copy[`${value}Url`] = previousValue;
    }
    setOnboardingForm(copy);
  };

  const handleSocialMediaBlurred = (e: CustomEvent) => {
    const { value } = e.target;

    const sanitizedInput = sanitizeInput(value.trim());

    if (sanitizedInput && !isValidUsername(sanitizedInput)) {
      setUsernameValidation(
        "Must contain only letters, numbers, underscores, hyphens or periods",
      );
    } else {
      setUsernameValidation(undefined);
    }
  };

  const handleSocialMediaChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const sanitizedInput = sanitizeInput(value.trim());

    const socialMediaUrl = `${SocialMediaFields[name]?.url}${sanitizedInput}`;

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = socialMediaUrl;
    setOnboardingForm(copy);

    if (isValidUsername(value)) {
      setUsernameValidation(undefined);
    }
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();

    const copyForm = { ...onboardingForm };
    let isEdited = false;

    if (
      !onboardingForm.primarySocialMediaPlatform &&
      onboardingForm.instagramUrl
    ) {
      copyForm.primarySocialMediaPlatform = PLATFORM_DEFAULT;
      isEdited = true;
    }

    if (
      copyForm[`${copyForm.primarySocialMediaPlatform}Url`] ===
      SocialMediaFields[`${copyForm.primarySocialMediaPlatform}Url`]?.url
    ) {
      copyForm[`${copyForm.primarySocialMediaPlatform}Url`] = "";
      isEdited = true;
    }

    if (isEdited) {
      setOnboardingForm(copyForm);
    }

    saveOnboardingForm(copyForm, location.pathname);
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  return (
    <form onSubmit={handleNext}>
      {!isProfilePage && (
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-hashtag"
          />
          Your Social Media Engagement
        </Typography>
      )}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md>
          <FormHelperText sx={styles.helperText}>
            Primary social channel (where you have the most followers or brand
            partnerships)
          </FormHelperText>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md="auto">
              <Select
                sx={styles.selectSocial}
                name="primarySocialMediaPlatform"
                onChange={handlePlatformChange}
                value={
                  onboardingForm.primarySocialMediaPlatform || PLATFORM_DEFAULT
                }
              >
                {SocialMediaPlatforms.map((platform) => (
                  <MenuItem key={platform.key} value={platform.key}>
                    {platform.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md>
              <TextField
                sx={styles.textField}
                fullWidth
                name={`${
                  onboardingForm.primarySocialMediaPlatform || PLATFORM_DEFAULT
                }Url`}
                placeholder="handle"
                error={!!usernameValidation}
                helperText={usernameValidation}
                onChange={handleSocialMediaChange}
                onBlur={handleSocialMediaBlurred}
                value={
                  sanitizeInput(
                    onboardingForm?.[
                      `${
                        onboardingForm.primarySocialMediaPlatform ||
                        PLATFORM_DEFAULT
                      }Url`
                    ],
                  ) || ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={styles.linkAdornment}
                      disablePointerEvents
                      position="start"
                    >
                      {
                        SocialMediaFields[
                          `${
                            onboardingForm.primarySocialMediaPlatform ||
                            PLATFORM_DEFAULT
                          }Url`
                        ]?.url
                      }
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormHelperText sx={styles.helperText}>
            Number of followers
          </FormHelperText>
          <TextField
            sx={styles.textField}
            fullWidth
            name="numOfFollowers"
            placeholder="134K"
            onChange={handleChange}
            value={onboardingForm?.numOfFollowers || ""}
          />
        </Grid>
      </Grid>

      <FormHelperText sx={styles.helperText}>
        What kind of engagement results have you seen from your content?
      </FormHelperText>
      <TextField
        sx={styles.textField}
        fullWidth
        name="engagement"
        multiline
        minRows={2}
        placeholder="My posts generate between 2-3K engagements (roughly a 6% engagement rate) and here is an example of a viral post I recently did - https://www.instagram.com/p/C4pwcJLPaBV/"
        onChange={handleChange}
        value={onboardingForm?.engagement || ""}
      />
      {!isProfilePage && (
        <Grid container justifyContent="flex-start" sx={{ mt: 2 }}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button disableElevation type="submit" variant="contained">
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default EngagementForm;
