const styles = {
  planDescription: {
    mb: 2,
  },
  warningText: {
    color: "#A93226",
  },
  textContainer: {
    mb: { xs: 0, md: 1 },
  },
  chip: {
    ml: 1,
    color: "inherit",
    borderRadius: 2,
  },
  strike: {
    textDecoration: "line-through",
    color: "info.main",
  },
  moneyBack: {
    backgroundColor: "secondary.light",
    width: "max-content",
    fontSize: 12,
    p: 1,
    py: 0.5,
    borderRadius: 2,
  },
  onboarding: {
    backgroundColor: "secondary.light",
    p: 3,
    borderRadius: 4,
  },
};

export default styles;
