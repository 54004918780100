import { grey } from "@mui/material/colors";
import { HOME_BODY_COLOR } from "styles/oneOffColor";

const styles = {
  root: {
    backgroundColor: HOME_BODY_COLOR,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    position: "fixed",
    overflowY: "auto",
    px: { xs: 0, sm: 3, md: 5, lg: 20 },
    py: { xs: 0, sm: 2, md: 5 },
    opacity: 1,
    backgroundSize: "22px 22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    borderRadius: { xs: 0, sm: 4 },
    backgroundColor: HOME_BODY_COLOR,
    maxWidth: { lg: "1024px" },
    p: { xs: 3, sm: 5, md: 8 },
  },
  fieldContainer: {
    mb: 2,
  },
  helperText: {
    mb: 0.5,
  },
  textField: {
    mb: 2,
    "& .MuiInputBase-root": { backgroundColor: "white" },
    "& .MuiFormHelperText-root": { color: "error.main" },
  },
  linkAdornment: {
    "& .MuiTypography-root": {
      color: grey[900],
      fontSize: { xs: 12, md: 14 },
    },
  },
  selectSocial: {
    backgroundColor: "white",
    width: "120px",
  },
  autocomplete: { backgroundColor: "white", mb: 2 },
  removeButton: {
    color: "secondary.main",
    fontSize: 12,
    width: 20,
    height: 20,
  },
  pitchIcon: {
    color: "inherit",
    mr: 1,
  },
  pitchButton: {
    textTransform: "none",
    color: grey[700],
    borderColor: grey[700],
    borderRadius: "20px",
    px: 1.5,
    "&:hover": {
      color: "black",
    },
    fontSize: 13,
  },
  selectedPitchButton: {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "white",
    },
  },
  button: {
    textTransform: "none",
    color: grey[400],
    borderRadius: 0,
    borderColor: grey[400],
    "&:hover": {
      color: "black",
    },
    fontSize: 13,
  },
  selected: {
    color: "black",
    borderColor: "black",
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[50],
    },
    fontSize: 13,
  },
  loadingSkeleton: {
    width: "100%",
    border: `1px solid ${grey[200]}`,
    height: 217.5,
    borderRadius: 4,
  },
  loaderWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  templateWrapper: {
    position: "relative",
    height: "300px",
    borderRadius: "10px",
    mx: -2,
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
  },
  quickSendWrapper: {
    position: "relative",
    overflowY: "auto",
    height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 325px)" },
    minHeight: "500px",
    mx: -2,
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
  },
  draftTemplateWrapper: {
    mx: { lg: "-150px", md: "-100px", sm: "-70px", xs: 0 },
    width: {
      lg: "calc(100% + 300px)",
      md: "calc(100% + 160px)",
      sm: "calc(100% + 120px)",
      xs: "100%",
    },
  },
};

export default styles;
