import { Grid } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { VAStatus } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { accessToVaFeature } from "utils/influencer";

import VAAnnouncement from "./VAAnnouncement";
import AnnualVAProgressBar from "./VAProgressBar";
import DiscoverVideo from "./Video";

const DiscoverBanner = () => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { subscription, isVaPlan } = useContext(SubscriptionContext);
  const [closeVa, setCloseVa] = useState(false);

  const isVAAnnualSubscriber =
    currentOrg &&
    subscription &&
    subscription?.isSubscribed &&
    subscription?.subscription?.planName === Plans.VA_YEARLY;

  const notQualifiedForVa =
    currentOrg &&
    [VAStatus.disqualified, VAStatus.not_interested].includes(
      currentOrg?.vaStatus,
    );
  const alreadyHasVA = accessToVaFeature(currentOrg, subscription, isVaPlan);

  const displayVAAnnouncement = !alreadyHasVA && !notQualifiedForVa;

  return notQualifiedForVa || closeVa ? (
    <Grid container justifyContent="center">
      <Grid sx={{ width: 850, mb: { xs: 2, lg: 0 } }}>
        <DiscoverVideo />
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center">
      <Grid item lg={1.5} sx={{ px: -2 }}></Grid>
      <Grid item lg>
        {isVAAnnualSubscriber && <AnnualVAProgressBar />}
        {displayVAAnnouncement && (
          <VAAnnouncement close={closeVa} setClose={setCloseVa} />
        )}
      </Grid>
    </Grid>
  );
};

export default DiscoverBanner;
