import { Box, Button, Grid, Typography } from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IncomeLevel, IncomeLevelMap } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { CustomEvent } from "schemas/functions";

import { vaOnboardingNavigate } from "../../Onboarding/helper";
import styles from "./styles";

interface Props {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

const IncomeForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    if (!onboardingForm.incomeLevel) {
      return;
    }

    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
    );
  };

  const handleSelect = (incomeLevel: IncomeLevel) => {
    const onboardingFormCopy = { ...onboardingForm };
    onboardingFormCopy.incomeLevel = incomeLevel;
    onboardingFormCopy.monthlyIncome = IncomeLevelMap[incomeLevel];
    setOnboardingForm(onboardingFormCopy);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mt: 0 }}
        >
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-dollar-sign"
          />
          Your Income
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          How much money have you made in content creation in{" "}
          <strong>the last 3 months</strong>?*
        </Typography>

        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          gap={2}
          sx={{ mb: 2 }}
        >
          <Box
            sx={[
              styles.option,
              onboardingForm.incomeLevel === IncomeLevel.starting &&
                styles.selectedOption,
            ]}
            onClick={() => handleSelect(IncomeLevel.starting)}
          >
            <Box sx={styles.emoji}>🐣</Box>

            <Typography sx={styles.header}>Just starting!</Typography>
            <Typography>$0</Typography>
          </Box>

          <Box
            sx={[
              styles.option,
              onboardingForm.incomeLevel === IncomeLevel.gettingFeetWet &&
                styles.selectedOption,
            ]}
            onClick={() => handleSelect(IncomeLevel.gettingFeetWet)}
          >
            <Box sx={styles.emoji}>🌱</Box>

            <Typography sx={styles.header}>
              Just getting my feet wet!
            </Typography>
            <Typography> &#x3c; $500</Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          gap={2}
          sx={{ mb: 2 }}
        >
          <Box
            sx={[
              styles.option,
              onboardingForm.incomeLevel === IncomeLevel.gettingHangOfIt &&
                styles.selectedOption,
            ]}
            onClick={() => handleSelect(IncomeLevel.gettingHangOfIt)}
          >
            <Box sx={styles.emoji}>🚀</Box>

            <Typography sx={styles.header}>Getting the hang of it!</Typography>
            <Typography>$500 - $2k</Typography>
          </Box>

          <Box
            sx={[
              styles.option,
              onboardingForm.incomeLevel === IncomeLevel.seasoned &&
                styles.selectedOption,
            ]}
            onClick={() => handleSelect(IncomeLevel.seasoned)}
          >
            <Box sx={styles.emoji}>💸</Box>

            <Typography sx={styles.header}>I'm Seasoned</Typography>
            <Typography>$2k+</Typography>
          </Box>
        </Grid>

        <Grid item xs="auto" container columnGap={1}>
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button
              disableElevation
              variant="contained"
              type="submit"
              disabled={!onboardingForm.incomeLevel}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default IncomeForm;
