import { useAuth } from "@clerk/clerk-react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AutomationsContext } from "contexts/Automations";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { VAStatus } from "schemas/dashboard";
import { routes } from "schemas/routes";
import { barWidth } from "styles/theme";

import Alert from "components/Alert";
import Loading from "components/Loading";
import PermanentDrawer from "components/PermanentDrawer";
import HomeSideBar from "features/Home/Sidebar";
import DetailedBrandView from "features/Influencer/Discover/DetailedBrandView";
import DraftEmailBar from "features/Influencer/Discover/DraftBar";
import QuickSendEmailBar from "features/Influencer/Discover/EmailBar";
import RequestBrandDialog from "features/Influencer/Discover/Results/RequestBrand";
import QuickSendIndividualDrawer from "features/Influencer/QuickSendIndividualDrawer";
import ComposeDraftDrawer from "features/Influencer/QuickSendIndividualDrawer/ComposeDraftDrawer";
import CreateTemplate from "features/Influencer/Templates/TemplatesTable/CreateTemplate";
import { fetcherAuth } from "utils/api";
import { FEEDBACK_GIVEN } from "utils/localStorage";
import { identifyUser, trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useAlert } from "utils/useAlert";
import { usePageSize } from "utils/usePageSize";

import FavoritesList from "./Sidebar/FavoritesList";
import TopNavigation from "./TopNavigation";
import { UpgradeDialog } from "./UpgradeDialog";
import { NotInterested } from "./UpgradeDialog/NotInterested";
import styles from "./styles";

export default function Home() {
  const [message, severity, setAlert, closeAlert] = useAlert();
  const { getToken, isSignedIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { fetchOutreachTemplates } = useContext(OutreachTemplatesContext);

  const location = useLocation();

  const { isLargeRecommendedPage } = usePageSize();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Contexts
  const {
    currentUser,
    currentOrg,
    isInfluencer,
    setCurrentOrg,
    setCurrentUser,
    setUserIntegrations,
    setUserNotifications,
    setProfile,
    setEmailSettings,
    setHideCityDialog,
  } = useContext(OrganizationUserContext);
  const { brandDetailsId, draftOpen } = useContext(QuickSendDrawerContext);
  const { createTemplateDialogOpen } = useContext(OutreachTemplatesContext);
  const { fetchCollections } = useContext(SavedBrandCollectionsContext);
  const { fetchUserRequest } = useContext(UserRequestsContext);
  const {
    subscription,
    setSubscription,
    upgradeDialogSource,
    setUpgradeDialogSource,
  } = useContext(SubscriptionContext);
  const { fetchAutomations } = useContext(AutomationsContext);
  const {
    emailHealth,
    fetchIntegrationLoading,
    fetchIntegrationHealth,
    setOpenIntegrationDialog,
  } = useContext(UserIntegrationsContext);
  const { discoverTab, tabWidth, setDiscoverTab } =
    useContext(DiscoverViewContext);
  const hasAlreadyGivenFeedback =
    localStorage.getItem(`${FEEDBACK_GIVEN}-${currentOrg?.id}`) === "true";
  const openConnectToGmail =
    searchParams.get("open_integration_dialog") === "true";

  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(true);
  const [notInterestedOpen, setNotInterestedOpen] = useState(false);

  const fetchUser = async () => {
    setFetchUserLoading(true);

    if (currentUser && currentOrg?.id) {
      const subscribeQuery = searchParams.get("subscribe");
      if (subscribeQuery) {
        trackEvent("Visited Page with Subscribe Dialog", {
          params: subscribeQuery,
        });
        setUpgradeDialogSource("Link Query");
      }

      await fetchIntegrationHealth(currentOrg.id);
      setFetchUserLoading(false);
      return;
    }

    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);

      const hasOnboarded = Boolean(res?.organizationProfile?.hasOnboarded);
      const isInVaOnboardingFlow =
        res.organization.vaStatus === VAStatus.pending ||
        (res.organization.signedUpForVa && !res.organization.vaStatus);

      const isMonthlyVaEmail =
        searchParams.get("ref") === "va_subscribed_monthly";

      // If user is a Monthly VA user, and click a link from the VA Subscribed Monthly email
      // let them bypass to the dashboard.
      if (!isMonthlyVaEmail) {
        if (isInVaOnboardingFlow) {
          navigate(`/${routes.onboardingVaInfluencer}${location.search}`);
          return;
        }

        if (!hasOnboarded) {
          navigate(`/${routes.onboardingInfluencer}${location.search}`);
          setHideCityDialog(true);
          return;
        }
      }

      if (res.organizationProfile) {
        const copy = makeDeepCopy(res.organizationProfile);
        setProfile(copy);
      }

      const subscribeQuery = searchParams.get("subscribe");
      if (subscribeQuery) {
        trackEvent("Visited Page with Subscribe Dialog", {
          params: subscribeQuery,
        });
        setUpgradeDialogSource("Link Query");
      }

      await fetchIntegrationHealth(res.organization.id);
    } catch (error) {
      // the user might not be created, go to onboarding
      navigate(`/${routes.onboardingInfluencer}${location.search}`);
    } finally {
      setFetchUserLoading(false);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    } else {
      setFetchUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchOutreachTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    const checkoutInfo = searchParams.get("checkout");
    if (checkoutInfo === "success") {
      setAlert(
        <>
          Thank you for subscribing to Bento. You can visit our settings page to
          manage your subscription!
        </>,
        "success",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!fetchIntegrationLoading && !emailHealth) {
      checkIfUserHasSentEmail();
    } else if (emailHealth && openConnectToGmail) {
      setAlert("You have successfully connected to Gmail!", "success");
      searchParams.delete("open_integration_dialog");
      setSearchParams(searchParams);
    } else if (!emailHealth && openConnectToGmail) {
      setOpenIntegrationDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth, fetchIntegrationLoading, currentOrg?.id]);

  const header = !isMobileScreen && !isInfluencer && (
    <Grid container sx={styles.pageHeader}>
      <IconButton
        onClick={() => navigate(`${routes.opportunities}`)}
        sx={styles.edit}
      >
        <Box component="i" className="fa-regular fa-gear" />
      </IconButton>
      {currentUser && currentUser?.profileImageUrl && (
        <Avatar src={currentUser?.profileImageUrl} sx={styles.userImage} />
      )}

      <Grid item sx={{ ml: 2 }}>
        <Typography>{currentUser?.name}</Typography>
        {!isInfluencer && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5)", fontSize: 12 }}>
            {currentOrg?.name}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  const handleCloseUpgradeDialog = () => {
    if (
      !hasAlreadyGivenFeedback &&
      subscription &&
      subscription?.remainingMessages <= 0
    ) {
      setNotInterestedOpen(true);
    }
    setUpgradeDialogSource("");
  };

  const handleCloseNotInterestedDialog = () => {
    setUpgradeDialogSource("");
    setNotInterestedOpen(false);
    searchParams.delete("checkout");
    setSearchParams(searchParams);
  };

  const checkIfUserHasSentEmail = async () => {
    if (!currentOrg?.id) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/has-sent-emails`,
      );
      if (res.hasSentEmails) {
        setOpenIntegrationDialog(true);
      }
    } catch (error) {
      setAlert(
        error?.message || "Something went wrong. Please reload and try again",
        "error",
      );
    }
  };

  useEffect(() => {
    fetchCollections();
    fetchUserRequest(true);
    fetchUserRequest(false);
    fetchAutomations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <Grid>
      {location.pathname.includes("portfolio-sites") &&
      location.pathname.includes("edit/design") ? (
        <Outlet />
      ) : (
        <>
          <TopNavigation />
          <Grid sx={styles.body}>
            {header}
            <Box
              sx={[
                styles.wrapper,
                {
                  mr: {
                    xs: 1,
                    md:
                      discoverTab !== null
                        ? `${tabWidth + barWidth}px`
                        : `${barWidth}px`,
                  },
                },
              ]}
            >
              <Outlet />
            </Box>
            {!isMobileScreen && <HomeSideBar />}
          </Grid>
          {isMobileScreen && (
            <FavoritesList closeDialog={() => setDiscoverTab(null)} />
          )}

          {/* Recommendation page has their own display of QuickSend drawer and brand details view */}
          {!isLargeRecommendedPage && (
            <>
              {!isMobileScreen && <QuickSendEmailBar />}
              <QuickSendIndividualDrawer />
              {brandDetailsId > -1 && <DetailedBrandView />}
            </>
          )}

          {!isMobileScreen && <DraftEmailBar />}
          {draftOpen && <ComposeDraftDrawer />}

          {!isMobileScreen && (
            <PermanentDrawer
              open={discoverTab !== null}
              drawerWidth={tabWidth}
            />
          )}
          {createTemplateDialogOpen && <CreateTemplate />}
        </>
      )}

      {upgradeDialogSource !== "" && (
        <UpgradeDialog
          open={upgradeDialogSource !== ""}
          onClose={handleCloseUpgradeDialog}
          setAlert={setAlert}
        />
      )}

      <NotInterested
        open={notInterestedOpen || searchParams.get("checkout") === "failed"}
        close={handleCloseNotInterestedDialog}
        setAlert={setAlert}
      />

      <RequestBrandDialog />

      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </Grid>
  );
}
