const styles = {
  container: {
    my: 2,
    p: 3,
    backgroundColor: "yellow.light",
    borderRadius: 4,
  },
  close: {
    position: "absolute",
    right: 10,
    top: 20,
    m: 1,
    width: 20,
    height: 20,
    fontSize: 14,
  },
};

export default styles;
