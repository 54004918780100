import { Box, IconButton, Theme, Typography } from "@mui/material";
import {
  BubbleMenu,
  EditorContent,
  Editor as TipTapEditor,
} from "@tiptap/react";
import { useRef, useState } from "react";

import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import { Url, useLinkEditor } from "utils/useLinkEditor";
import { useVariablEditor } from "utils/useVariablesEditor";

import { LinkModal } from "./LinkModal";
import MenuOptions from "./Menu";
import MergeTagMenuBar from "./MergeTag/MergeTagMenuBar";
import { VariableModal } from "./Variable/VariableModal";
import styles from "./styles";

interface MenuBarProps {
  editor: TipTapEditor;
  allowAttachment: boolean;
  attachment?: string;
  openAttachmentManager?: () => void;
  removeAttachment?: () => void;
  hideMenu?: boolean;
  isBubble?: boolean;
  mergeTags?: string[];
  allowImage?: boolean;
  hasBorder?: boolean;
  portfolioTextId?: string;
  customTheme?: Theme;
  editorDecoratorBelowMenuBar?: JSX.Element;
  showVariableError?: boolean;
}

const MenuBar = ({
  editor,
  allowAttachment,
  attachment,
  openAttachmentManager,
  removeAttachment,
  isBubble,
  hideMenu,
  mergeTags,
  allowImage,
  hasBorder = true,
  portfolioTextId = undefined,
  customTheme = undefined,
  editorDecoratorBelowMenuBar = undefined,
  showVariableError = false,
}: MenuBarProps) => {
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const {
    modalIsOpen,
    url,
    setUrl,
    openModal,
    closeModal,
    saveLink,
    removeLink,
  } = useLinkEditor({ editor });

  const {
    variableModalIsOpen,
    variable,
    openVariableFilledModal,
    deleteVariableFilled,
    handleSaveVariable,
    closeVariableModal,
  } = useVariablEditor({ editor });

  const [selectImageOpen, setSelectImageOpen] = useState<boolean>(false);

  if (!editor) {
    return null;
  }

  const menuOptions = (
    <MenuOptions
      editor={editor}
      openModal={openModal}
      openVariableFilledModal={openVariableFilledModal}
      handleDeleteVariable={deleteVariableFilled}
      isBubble={isBubble}
      portfolioTextId={portfolioTextId}
      openAttachmentManager={openAttachmentManager}
      allowAttachment={allowAttachment}
      allowImage={allowImage}
      fromQuickSend={showVariableError}
      addImage={() => setSelectImageOpen(true)}
    />
  );

  const selectedImage = (imageUrl: string) => {
    editor.chain().focus().setImage({ src: imageUrl, size: "large" }).run();
  };

  return (
    <Box
      sx={[
        styles.wrapper,
        hasBorder ? styles.borderWrapper : {},
        isBubble ? {} : styles.normalEditorWrapper,
      ]}
    >
      {!hideMenu && (
        <>
          {isBubble ? (
            <BubbleMenu
              shouldShow={({ editor }) => {
                return editor.isFocused;
              }}
              tippyOptions={{
                placement: "top", // Place above the editor
                getReferenceClientRect: () => {
                  return (
                    editorContainerRef.current?.getBoundingClientRect() ||
                    new DOMRect()
                  );
                },
                arrow: false,
                zIndex: 1200,
              }}
              editor={editor}
            >
              {menuOptions}
            </BubbleMenu>
          ) : (
            menuOptions
          )}
        </>
      )}

      {allowImage && (
        <AttachmentManager
          enteredSelection={selectedImage}
          open={selectImageOpen}
          setOpen={setSelectImageOpen}
          imageOnly={true}
        />
      )}

      <MergeTagMenuBar
        editor={editor}
        hideMenu={hideMenu}
        mergeTags={mergeTags}
      />

      {editorDecoratorBelowMenuBar}

      <Box
        sx={[
          styles.editor,
          !!portfolioTextId && !!customTheme
            ? styles.portfolioEditor(customTheme)
            : styles.emailEditor(showVariableError),
          hideMenu ? styles.plainEditor : {},
        ]}
        ref={editorContainerRef}
      >
        <EditorContent spellCheck={false} editor={editor} />
      </Box>
      {allowAttachment && (
        <>
          {attachment && (
            <Box sx={styles.attachmentContainer}>
              <Box className="fa-regular fa-paperclip" sx={{ mr: 1 }} />{" "}
              <Typography color="text.secondary" sx={styles.attachmentName}>
                {attachment.split("/")[attachment.split("/").length - 1]}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={openAttachmentManager}
              >
                <Box component="i" className="fa-solid fa-edit" />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={removeAttachment}
              >
                <Box component="i" className="fa-solid fa-trash" />
              </IconButton>
            </Box>
          )}
        </>
      )}
      <LinkModal
        url={url}
        closeModal={closeModal}
        open={modalIsOpen}
        onChangeUrl={(newUrl: Url) => setUrl(newUrl)}
        onSaveLink={saveLink}
        onRemoveLink={removeLink}
      />
      <VariableModal
        variable={variable}
        open={variableModalIsOpen}
        closeModal={closeVariableModal}
        onSave={handleSaveVariable}
      />
    </Box>
  );
};

interface EditorProps {
  editor: TipTapEditor;
  allowAttachment?: boolean;
  attachment?: string;
  openAttachmentManager?: () => void;
  removeAttachment?: () => void;
  isBubble?: boolean;
  hideMenu?: boolean;
  mergeTags?: string[];
  allowImage?: boolean;
  hasBorder?: boolean;
  portfolioTextId?: string;
  customTheme?: Theme;
  editorDecoratorBelowMenuBar?: JSX.Element;
  showVariableError?: boolean;
}

const Editor = ({
  editor,
  allowAttachment = false,
  attachment = undefined,
  openAttachmentManager = undefined,
  removeAttachment = undefined,
  isBubble = false,
  hideMenu = false,
  mergeTags = [],
  allowImage = false,
  hasBorder = true,
  portfolioTextId = undefined,
  customTheme = undefined,
  editorDecoratorBelowMenuBar = undefined,
  showVariableError = false,
}: EditorProps) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {editor && (
        <MenuBar
          editor={editor}
          allowAttachment={allowAttachment}
          attachment={attachment}
          openAttachmentManager={openAttachmentManager}
          removeAttachment={removeAttachment}
          isBubble={isBubble}
          hideMenu={hideMenu}
          mergeTags={mergeTags}
          allowImage={allowImage}
          hasBorder={hasBorder}
          portfolioTextId={portfolioTextId}
          customTheme={customTheme}
          editorDecoratorBelowMenuBar={editorDecoratorBelowMenuBar}
          showVariableError={showVariableError}
        />
      )}
    </Box>
  );
};

export default Editor;
