import {
  Identify,
  add,
  getDeviceId,
  identify,
  init,
  reset,
  track,
} from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

import { getPageName } from "constants/trackingProps";

import { ON_BEHALF_OF_TOKEN } from "./localStorage";

export const isAdmin = sessionStorage.getItem(ON_BEHALF_OF_TOKEN);
const isAdminPage = window.location.href?.includes("/admin");

const doNotTrack = isAdmin || isAdminPage;
if (!doNotTrack) {
  init(process.env.REACT_APP_AMPLITUDE_API_KEY || "", {
    defaultTracking: true,
  });
  // Create and Install Session Replay Plugin
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.5,
  });
  add(sessionReplayTracking);
}

export const trackEvent = (
  eventType: string,
  eventProperties?: Record<string, any>,
) => {
  if (isAdmin) {
    return;
  }
  const queryParams = new URLSearchParams(window.location.search);
  let data = {
    destination: window.location.hash,
    location: window.location.href,
    source: queryParams.get("s"),
    type: queryParams.get("ref"),
  };
  const pageProperties = { ...data, "Page Name": getPageName() };

  if (eventProperties) {
    data = { ...data, ...eventProperties, ...pageProperties };
  }
  if (eventProperties) {
    track(eventType, data);
  } else {
    track(eventType, pageProperties);
  }
};

export const identifyUser = (
  userId: string,
  userProperties?: Record<string, any>,
) => {
  if (isAdmin) {
    return;
  }

  const event = new Identify();
  if (userProperties) {
    for (const key in userProperties) {
      event.set(key, userProperties[key]);
    }
  }
  identify(event, { user_id: `user-${userId}` });
};

export const resetTracking = () => {
  reset();
};

export const getUserDeviceId = () => {
  return getDeviceId();
};

export const getSource = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("s");
  return source;
};
