import { grey } from "@mui/material/colors";

const styles = {
  option: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: grey[100],
    py: { xs: 1, lg: 4 },
    borderRadius: 2,
    width: { xs: "100%", md: "50%", lg: "30%" },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey[200],
    },
  },
  header: {
    fontWeight: "bold",
    mb: 1,
    fontSize: 14,
  },
  selectedOption: {
    border: "2px solid",
    borderColor: "primary.main",
  },
  emoji: {
    fontSize: { xs: 20, md: 40 },
  },
};

export default styles;
