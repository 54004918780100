import { Divider, Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SetAlertType } from "schemas/functions";

import Settings from "../Settings";
import AdminSearchThreads from "../Threads";

interface AdminOrganizationViewProps {
  setAlert: SetAlertType;
  setSelectedOrgId: Dispatch<SetStateAction<number>>;
}

export default function AdminOrganizationView({
  setAlert,
  setSelectedOrgId,
}: AdminOrganizationViewProps) {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setSelectedOrgId(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return id ? (
    <Grid>
      <Settings organizationId={Number(id)} setAlert={setAlert} />
      <Divider sx={{ my: 3 }} />
      <Typography variant="h4" sx={{ my: 2 }}>
        <strong>View Emails</strong>
      </Typography>
      <AdminSearchThreads organizationId={Number(id)} setAlert={setAlert} />
    </Grid>
  ) : (
    <></>
  );
}
