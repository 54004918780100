import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Subscription } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "utils/api";

import { OrganizationUserContext } from "./Organization";

export enum SubscriptionTab {
  yearly = "yearly",
  monthly = "monthly",
}

export type AllPlans = {
  [key in Plans]: {
    display_name: string;
    monthly_limit: number;
    price: number;
    chat_monthly_limit: number;
    brand_requests_monthly_limit: number;
    refresh_recommendations_limit: number;
  };
};

interface SubscriptionContextInterface {
  subscription: Subscription | null;
  setSubscription: React.Dispatch<SetStateAction<Subscription | null>>;
  canMakeBrandRequest: () => boolean;
  clearSubscriptionContext: () => void;
  plans: AllPlans | null;
  fetchPlans: () => void;
  decreaseEmailCount: () => void;
  decreaseBrandRequestsCount: () => void;
  isProPlan: boolean;
  isFreePlan: boolean;
  isStarterPlan: boolean;
  isVaPlan: boolean;
  referralCode: string | null;
  setReferralCode: React.Dispatch<SetStateAction<string | null>>;
  setUpgradeDialogSource: React.Dispatch<SetStateAction<string>>;
  upgradeDialogSource: string;
  emailSents: number;
  brandRequestsMade: number;
  customizedMessagesDrafted: number;
  recommendationsRefreshed: number;
  tab: SubscriptionTab;
  setTab: Dispatch<SetStateAction<SubscriptionTab>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  subscription: null,
  setSubscription: defaultContextMissingFunction,
  updateSubscription: defaultContextMissingFunction,
  canMakeBrandRequest: defaultContextMissingFunction,
  clearSubscriptionContext: defaultContextMissingFunction,
  plans: null,
  fetchPlans: defaultContextMissingFunction,
  decreaseEmailCount: defaultContextMissingFunction,
  decreaseBrandRequestsCount: defaultContextMissingFunction,
  isProPlan: false,
  isFreePlan: true,
  isStarterPlan: false,
  isVaPlan: false,
  referralCode: null,
  setReferralCode: defaultContextMissingFunction,
  upgradeDialogSource: "",
  setUpgradeDialogSource: defaultContextMissingFunction,
  emailSents: 0,
  brandRequestsMade: 0,
  customizedMessagesDrafted: 0,
  recommendationsRefreshed: 0,
  tab: SubscriptionTab.yearly,
  setTab: defaultContextMissingFunction,
};

const SubscriptionContext =
  createContext<SubscriptionContextInterface>(defaultInterface);

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [upgradeDialogSource, setUpgradeDialogSource] = useState<string>("");
  const [plans, setPlans] = useState<AllPlans | null>(null);
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [tab, setTab] = useState<SubscriptionTab>(SubscriptionTab.yearly);
  const [searchParams] = useSearchParams();

  const clearSubscriptionContext = () => {
    setSubscription(null);
  };

  const canMakeBrandRequest = () => {
    return Boolean(subscription && subscription.remainingBrandRequests > 0);
  };

  const fetchPlans = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/subscription-plans`,
      );
      setPlans(res);
    } catch (error) {}
  };

  const fetchReferralCode = async () => {
    try {
      const { referralCode } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/referrals/code`,
      );
      setReferralCode(referralCode);
    } catch (error) {}
  };

  const decreaseBrandRequestsCount = () => {
    setSubscription((s) => {
      if (s) {
        return {
          ...s,
          remainingBrandRequests: s.remainingBrandRequests - 1,
        };
      } else {
        return s;
      }
    });
  };

  const decreaseEmailCount = () => {
    setSubscription((s) => {
      if (s) {
        return {
          ...s,
          remainingMessages: s.remainingMessages - 1,
        };
      } else {
        return s;
      }
    });
  };

  const trackGoogleAdEvent = (sessionId: string) => {
    let value =
      plans?.[subscription?.subscription?.planName as Plans]?.price ?? 10;
    if (subscription?.subscription?.planName?.includes("yearly")) {
      value = value * 12;
    }
    // @ts-ignore
    window.gtag("event", "conversion", {
      send_to: "AW-11491430223/0se8CL2UlfYZEM-execq",
      value,
      currency: "USD",
      transaction_id: sessionId,
    });
  };

  const isProPlan = useMemo(() => {
    return (
      subscription?.subscription?.planName === Plans.PRO ||
      subscription?.subscription?.planName === Plans.PRO_YEARLY
    );
  }, [subscription]);

  const isFreePlan = useMemo(() => {
    return subscription?.subscription?.planName === Plans.FREE;
  }, [subscription]);

  const isStarterPlan = useMemo(() => {
    return (
      subscription?.subscription?.planName === Plans.STARTER ||
      subscription?.subscription?.planName === Plans.STARTER_YEARLY
    );
  }, [subscription]);

  const isVaPlan = useMemo(() => {
    return (
      subscription?.subscription?.planName === Plans.VA ||
      subscription?.subscription?.planName === Plans.VA_YEARLY
    );
  }, [subscription]);

  const emailSents = subscription?.monthlyLimit
    ? subscription?.monthlyLimit - subscription?.remainingMessages
    : 0;
  const brandRequestsMade = subscription?.brandRequestsMonthlyLimit
    ? subscription?.brandRequestsMonthlyLimit -
      subscription?.remainingBrandRequests
    : 0;
  const customizedMessagesDrafted = subscription?.chatMonthlyLimit
    ? subscription?.chatMonthlyLimit - subscription?.remainingChatMessages
    : 0;
  const recommendationsRefreshed =
    subscription?.recommendationsRefreshMonthlyLimit
      ? subscription?.recommendationsRefreshMonthlyLimit -
        subscription?.remainingRecommendationsRefresh
      : 0;

  useEffect(() => {
    if (currentOrg?.id) {
      fetchPlans();
      fetchReferralCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    const planName = subscription?.subscription?.planName;
    if (planName) {
      const isMonthly =
        planName === Plans.PRO ||
        planName === Plans.STARTER ||
        planName === Plans.VA;
      if (isMonthly && tab === SubscriptionTab.yearly) {
        setTab(SubscriptionTab.monthly);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.subscription?.planName]);

  useEffect(() => {
    const checkoutInfo = searchParams.get("checkout");
    if (checkoutInfo === "success" && subscription?.subscription.id && plans) {
      trackGoogleAdEvent(searchParams.get("session_id") || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, subscription?.subscription.id, plans]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        upgradeDialogSource,
        setUpgradeDialogSource,
        canMakeBrandRequest,
        clearSubscriptionContext,
        plans,
        fetchPlans,
        decreaseEmailCount,
        decreaseBrandRequestsCount,
        isProPlan,
        isFreePlan,
        isStarterPlan,
        isVaPlan,
        referralCode,
        setReferralCode,
        emailSents,
        brandRequestsMade,
        customizedMessagesDrafted,
        recommendationsRefreshed,
        tab,
        setTab,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionProvider, SubscriptionContext };
