import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface PartnershipFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
}

const PartnershipForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
}: PartnershipFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const handleSkip = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "partnershipForm",
    });

    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "next")}${
        location.search
      }`,
    );
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  return (
    <form onSubmit={handleNext}>
      {!isProfilePage && (
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-handshake-angle"
          />
          Past Partnerships
        </Typography>
      )}

      {(isProfilePage ||
        onboardingForm.defaultTemplateHighlights.includes(
          TemplateHighlight.CASE_STUDY,
        )) && (
        <Grid item xs={12}>
          <FormHelperText sx={styles.helperText}>
            Share an example of a past partnership and the results you created
            for them (share an example link if relevant)
          </FormHelperText>
          <TextField
            sx={styles.textField}
            fullWidth
            name="caseStudy"
            placeholder="I worked with Wayfair on an ad that generated 2M views!"
            onChange={handleChange}
            multiline
            minRows={2}
            value={onboardingForm?.caseStudy || ""}
          />
        </Grid>
      )}

      {(isProfilePage ||
        onboardingForm.defaultTemplateHighlights.includes(
          TemplateHighlight.PAST_PARTNERSHIPS,
        )) && (
        <Grid item xs={12}>
          <FormHelperText sx={styles.helperText}>
            Share 3-4 solid brands that you want to mention in your pitch
          </FormHelperText>
          <TextField
            sx={styles.textField}
            fullWidth
            name="mostImpressivePartnerships"
            placeholder="Skims, Motion, Amazon and Wayfair"
            onChange={handleChange}
            value={onboardingForm?.mostImpressivePartnerships || ""}
          />
        </Grid>
      )}

      {!isProfilePage && (
        <Grid
          container
          justifyContent="flex-start"
          columnGap={1}
          sx={{ mt: 2 }}
        >
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button onClick={handleSkip} color="secondary" disableElevation>
              Skip
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button disableElevation type="submit" variant="contained">
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default PartnershipForm;
