export const ON_BEHALF_OF_TOKEN = "onBehalfOfToken";
export const FEEDBACK_GIVEN = "feedbackGiven";
export const SCHEDULED_DATE_EMAIL = "scheduledDateEmail";
export const DISCOVER_SEARCH_PARAMS = "discoverSearchParams";
export const DISCOVER_METADATA_TAGS = "discoverMetadataTags";
export const CATEGORY_ALL_FILTER = "categoryFilterAll";
export const HIDE_ONBOARDING_VIDEOS = "hideOnboardingVideos";

// DIALOGS
export const DISPLAY_SCHEDULED_DIALOG = "displayScheduleDialog";
export const DISPLAYED_CITY_DIALOG = "displayedCityDialog";
export const DISPLAYED_CONSENT_DIALOG = "displayedConsentDialog";

// VA Steps
export const CURRENT_VA_STEP = "currentVaStep";
