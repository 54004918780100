import { FormHelperText, Grid } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";

import styles from "../styles";

interface CustomizePartOneEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

const SignatureForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: CustomizePartOneEmailFormProps) => {
  const location = useLocation();

  const editor = useEditor(
    {
      extensions: extensions({ hardBreaksOnly: true }),
      content: onboardingForm?.signature,
      onUpdate({ editor }) {
        setOnboardingForm((prev) => {
          return { ...prev, signature: editor.getHTML() };
        });
      },
    },
    [!!onboardingForm?.signature],
  ) as TipTapEditor;

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid sx={styles.fieldContainer}>
          <FormHelperText sx={styles.helperText}>
            Enter your email signature (image or text)
          </FormHelperText>
          <Editor editor={editor} allowAttachment={false} allowImage={true} />
        </Grid>
      </form>
    </>
  );
};

export default SignatureForm;
