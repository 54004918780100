import { Theme, lighten } from "@mui/material";
import { grey } from "@mui/material/colors";

const styles = {
  root: {
    background: "#fff",
    borderRadius: "0.5rem",
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "0.9rem",
    overflow: "hidden",
    padding: "0.2rem",
    position: "relative",
  },
  item: {
    background: "transparent",
    border: "1px solid transparent",
    borderRadius: "0.4rem",
    display: "block",
    margin: 0,
    padding: "0.2rem 0.4rem",
    textAlign: "left",
    width: "100%",
  },
  isSelected: {
    borderColor: "#000",
  },
  mergeTagContainer: (theme: Theme) => ({
    display: "flex",
    flexDirection: "row",
    backgroundColor: lighten(theme.palette.secondary.main, 0.3),
    pl: 2,
    pb: 1,
    pt: 1,
    alignItems: "center",
    fontSize: { xs: "11px", sm: "14px" },
  }),
  mergeTag: (theme: Theme) => ({
    borderRadius: "0.4rem",
    boxDecorationBreak: "clone",
    padding: "0.2rem 0.3rem",
    background: grey[100],
    color: "black",
    ml: 1,
    cursor: "pointer",
  }),
};

export default styles;
