import { capitalize } from "@mui/material";
import { LastContactedDate } from "contexts/QuickSend";
import { RecentContactDetails } from "contexts/QuickSendDrawer";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  OutreachDraft,
  OutreachEmailDraft,
  PreviewMessage,
} from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";
import { makeDeepCopy } from "utils/updateLocalState";

export const previewToDraft = (email: PreviewMessage) => {
  const { subject, body, attachment, templateId, templateName, contentType } =
    email;
  return {
    subject,
    body,
    attachment,
    email_template_id: templateId,
    email_template_name: templateName,
    content_type: contentType,
  };
};

export const draftToPreview = (
  draft: OutreachEmailDraft,
  contactEmail: string,
  draftTemplate: Template,
) => {
  const { subject, body, attachment, contentType } = draft;
  return {
    subject,
    body,
    attachment,
    templateId: draft?.emailTemplateId,
    contentType,
    to: contactEmail,
    templateName: draftTemplate?.name,
  };
};

export const getDraftTemplates = (
  draft: OutreachDraft,
  templates: Template[],
) => {
  const draftTemplates: Template[] = [];
  draft?.emailDrafts?.forEach(
    (emailDraft: OutreachEmailDraft, index: number) => {
      const template = templates.find(
        (template) =>
          template.id && template.id === emailDraft?.emailTemplateId,
      );
      // User could have deleted the original template where the draft comes from
      const templateName =
        template && template?.id
          ? `Draft - ${template?.name}`
          : `Draft - ${capitalize(emailDraft.contentType)} ${index + 1}`;

      draftTemplates.push({
        emailTemplateId: emailDraft?.emailTemplateId,
        name: templateName,
        subject: emailDraft.subject || "",
        body: emailDraft.body,
        attachment: emailDraft.attachment,
        contentType: emailDraft.contentType,
        isAutogenerated: draft.isAutogenerated,
      });
    },
  );
  return draftTemplates;
};

export const getEmailTemplates = (
  setTemplateForQuickSend: Dispatch<SetStateAction<Template[]>>,
  specificTemplate?: Template[],
  templateForQuickSend?: Template[],
  defaultTemplates?: Template[],
) => {
  let templates: Template[] = [];
  if (specificTemplate && specificTemplate?.length > 0) {
    templates = specificTemplate;
  } else if (templateForQuickSend && templateForQuickSend?.length > 0) {
    templates = templateForQuickSend;
  } else if (defaultTemplates) {
    templates = defaultTemplates;
  }

  const copy = makeDeepCopy(templates);
  for (const template of copy) {
    if (template.emailTemplateId) {
      template.id = template.emailTemplateId;
    }
  }

  setTemplateForQuickSend((prev) => {
    let copyQuickSend = makeDeepCopy(prev);
    if (prev.length === 0) {
      copyQuickSend = copy;
    }
    return copyQuickSend;
  });

  return copy;
};

export const getContactName = (
  bentoBrand: BentoBrand | null,
  bentoContact?: BentoContact | null,
) => {
  if (!bentoContact && bentoBrand) return `${bentoBrand.brandName} Team`;
  const firstName = !!bentoContact?.name
    ? bentoContact?.name.split(" ")?.[0]
    : "";
  return firstName || `${bentoBrand?.brandName} Team`;
};

export const checkNoRecentContact = async (
  sendAt: string | null,
  contact: BentoContact | NewBentoContact | null,
  updateLastContacted: (
    emailAddresses: string[],
    override?: boolean,
  ) => Promise<LastContactedDate[]>,
  setRecentContactDetails: Dispatch<
    SetStateAction<RecentContactDetails | null>
  >,
) => {
  if (contact?.email) {
    const [lastContacted] = await updateLastContacted([contact?.email]);
    if (
      lastContacted &&
      moment(lastContacted).isAfter(moment().subtract(24, "hours"))
    ) {
      setRecentContactDetails({ contact, sendAt });
      return false;
    }
  }
  return true;
};

export const replaceBodyCopied = (
  contactName: string | undefined,
  brandName: string | undefined,
  body: string,
) => {
  let s = body;
  if (contactName) {
    s = s.replaceAll(`{First Name}`, contactName);
  }
  if (brandName) {
    s = s.replaceAll(`{Brand Name}`, brandName);
  }
  return s;
};
