import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useEffect } from "react";
import { BentoBrand, SavedBrandStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  toggleBulkMode: () => void;
  isBulkMode: boolean;
  displayedBrands: BentoBrand[] | undefined;
}

const ListHeader = ({ toggleBulkMode, isBulkMode, displayedBrands }: Props) => {
  const { currentTab, setCurrentTab, selectedCollection } = useContext(
    SavedBrandCollectionsContext,
  );
  const { bulkBrands, setBulkBrands, selectAll, setSelectAll } =
    useContext(SavedBrandContext);

  const handleSelectAll = (e: CustomEvent) => {
    trackEvent("Favorites List - Select All Toggled", {
      "Collection ID": selectedCollection?.id,
    });
    const { checked } = e.target;
    if (checked) {
      setBulkBrands(displayedBrands || []);
    } else {
      setBulkBrands([]);
    }
    setSelectAll(checked);
  };

  const handleChange = (event: CustomEvent, newValue: string) => {
    trackEvent("Favorites List - Tab Changed", {
      Tab: newValue,
      "Collection ID": selectedCollection?.id,
    });
    setCurrentTab(newValue as SavedBrandStatus);
  };

  useEffect(() => {
    if (
      bulkBrands?.length === displayedBrands?.length &&
      !selectAll &&
      bulkBrands?.length > 0
    ) {
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkBrands?.length]);

  return selectedCollection ? (
    <Box>
      <Grid container sx={styles.tabContainer} justifyContent="space-between">
        <Grid item xs={8.5}>
          <Tabs value={currentTab} onChange={handleChange}>
            <Tab
              sx={styles.tab}
              value={SavedBrandStatus.unsent}
              label={<>Unsent ({selectedCollection.unsentCount})</>}
            />
            <Tab
              sx={styles.tab}
              value={SavedBrandStatus.sent}
              label={<>Sent ({selectedCollection.sentCount})</>}
            />
          </Tabs>
        </Grid>

        <Grid item xs>
          <Button
            size="small"
            onClick={toggleBulkMode}
            color={isBulkMode ? "primary" : "secondary"}
          >
            Select {bulkBrands?.length > 0 ? `(${bulkBrands?.length})` : ""}
          </Button>
        </Grid>
      </Grid>

      {isBulkMode && (
        <Grid sx={styles.selectAll}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleSelectAll}
                checked={selectAll}
                disableRipple
                icon={
                  <Box
                    sx={{ color: "green.main" }}
                    component="i"
                    className="fa-regular fa-square"
                  />
                }
                checkedIcon={
                  <Box
                    component="i"
                    className="fa-solid fa-square-minus"
                    sx={{ color: "green.main" }}
                  />
                }
              />
            }
            label={<Box sx={{ pr: 1 }}>Select All</Box>}
          />
        </Grid>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default ListHeader;
