import { grey } from "@mui/material/colors";

const styles = {
  description: {
    fontSize: 15,
    color: grey[700],
  },
};

export default styles;
