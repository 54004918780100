import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext } from "react";
import {
  BentoBrand,
  SavedBrand,
  SavedBrandCollection,
  SavedBrandCollectionsMap,
  SavedBrandStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "./api";
import { getNow } from "./time";

export const useCollection = () => {
  const {
    collections,
    setCollections,
    currentTab,
    updateCollection,
    getAllSavedBrands,
  } = useContext(SavedBrandCollectionsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const updateTotal = (
    collectionId: number,
    action: "add" | "remove",
    type: SavedBrandStatus,
    change = 1,
  ) => {
    updateCollection(collectionId, type, {
      newCount: (prevCount) => {
        prevCount += action === "add" ? change : -change;
        return prevCount;
      },
    });
  };

  const isSavedBrand = (brand: BentoBrand) => {
    return (
      getAllSavedBrands().findIndex(
        (x) => Number(brand.id) === Number(x?.bentoBrandId),
      ) > -1
    );
  };

  const modifySavedBrands = (
    brand: SavedBrand,
    action: "add" | "remove",
    collectionId?: number,
    type?: SavedBrandStatus,
  ) => {
    if (action === "add") {
      addToCollectionTab(brand, collectionId || 0, type || currentTab);
    } else {
      _remove(brand);
    }
  };

  const addDraftToCollection = (bentoBrand: BentoBrand) => {
    const collectionId = bentoBrand?.savedBrandCollectionId || 0;
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", true, true);
  };

  const removeDraftFromCollection = (
    collectionId: number,
    bentoBrand: BentoBrand,
  ) => {
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", false, false);
  };

  const isDraftBrand = (bentoBrand: BentoBrand) => {
    return Boolean(
      getAllSavedBrands().find(
        (sb) =>
          Number(sb?.bentoBrandId) === Number(bentoBrand.id) &&
          sb?.bentoBrand.hasOutreachDraft === true,
      ),
    );
  };

  const createOrUpdateCollection = async (
    name: string,
    collectionId?: number,
    isBulkMode?: boolean,
  ): Promise<SavedBrandCollection | undefined> => {
    if (!currentOrg?.id) return;
    let url = collectionId
      ? `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`
      : `/api/organization/${currentOrg.id}/saved-brand-collections`;
    try {
      const res = await fetcherAuth(
        getToken,
        url,
        collectionId ? "PUT" : "POST",
        {},
        { name },
      );
      if (collectionId) {
        setCollections((prev) => {
          const copy = { ...prev };
          if (copy[collectionId]) {
            copy[collectionId] = {
              ...copy[collectionId],
              ...res.savedBrandCollection,
            };
          }
          return copy;
        });

        if (!isBulkMode)
          setAlert("Successfully updated your collection", "success");
      } else {
        setCollections((prev) => {
          const copy = { ...prev };
          copy[res.savedBrandCollection.id] = {
            ...res.savedBrandCollection,
            unsentCount: 0,
            unsentBrands: [],
            sentCount: 0,
            sentBrands: [],
          };
          return copy;
        });
        if (!isBulkMode)
          setAlert("Successfully created your collection", "success");
      }
      return res.savedBrandCollection;
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteCollection = async (collectionId: number) => {
    if (!currentOrg?.id) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`,
        "DELETE",
      );
      setCollections((prev) => {
        const copy = { ...prev };
        delete copy[collectionId];
        return copy;
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const bulkMoveToSent = (bentoBrands: BentoBrand[]) => {
    setCollections((prev) => {
      const copy = { ...prev };
      for (const brand of bentoBrands) {
        // move brand out of all saved and their collection into sent folder
        if (brand.savedBrandCollectionId) {
          _removeFromCollection(
            brand.id,
            copy[brand.savedBrandCollectionId],
            SavedBrandStatus.unsent,
          );
          const savedBrand = {
            bentoBrand: brand,
            bentoBrandId: brand.id,
            createdAt: getNow(),
          };
          _addToCollection(
            savedBrand,
            copy[brand.savedBrandCollectionId],
            SavedBrandStatus.sent,
          );
        }

        // move in All Saved as well
        _removeFromCollection(brand.id, copy[0], SavedBrandStatus.unsent);
        const savedBrand = {
          bentoBrand: brand,
          bentoBrandId: brand.id,
          createdAt: getNow(),
        };
        _addToCollection(savedBrand, copy[0], SavedBrandStatus.sent);
      }
      return copy;
    });
  };

  const addToCollectionTab = (
    brand: SavedBrand,
    collectionId: number,
    newType: SavedBrandStatus,
  ) => {
    /**
     * This function adds a saved brand to a new collection ID. If the brand is not in all saved list,
     * it will also add to the all saved list. If the brand was previously in another list, it will
     * change the savedBrandCollectionId of the brand in the saved list to match the new collection ID.
     */

    setCollections((prev) => {
      const copy = { ...prev };

      const {
        customCollectionId,
        type: originalType,
        indexOfAllSaved,
      } = _findInCollection(brand.bentoBrand, copy);

      // remove this brand from the existing collection
      if (customCollectionId && originalType) {
        const collection = copy[customCollectionId];
        if (collection) {
          _removeFromCollection(brand.bentoBrandId, collection, originalType);
        }
      }

      // update the All Saved and the bento brand's savedBrandCollectionId in All Saved
      const allSavedCollection = copy[0];
      if (indexOfAllSaved !== undefined && originalType && allSavedCollection) {
        if (originalType === newType) {
          const currentBrands = allSavedCollection[`${originalType}Brands`];
          if (currentBrands) {
            currentBrands[indexOfAllSaved].bentoBrand.savedBrandCollectionId =
              collectionId;
          }
        } else {
          _removeFromCollection(
            brand.bentoBrandId,
            allSavedCollection,
            originalType,
          );
          _addToCollection(brand, allSavedCollection, newType);
        }
      } else {
        if (!customCollectionId) {
          _addToCollection(brand, allSavedCollection, newType);
        } else {
          // add to the list without incrementing total count as brand is already in collection
          allSavedCollection[`${newType}Brands`] = [
            brand,
            ...(allSavedCollection[`${newType}Brands`] || []),
          ];
        }
      }

      if (collectionId !== 0) {
        for (const collection of Object.values(copy)) {
          if (collection.id === collectionId && collection.id !== 0) {
            _addToCollection(brand, collection, newType);
          }
        }
      }

      return copy;
    });
  };

  const getTotal = () => {
    const allSavedCollection = collections[0];
    return (
      (allSavedCollection?.sentCount || 0) +
      (allSavedCollection?.unsentCount || 0)
    );
  };

  const _addToCollection = (
    brand: SavedBrand,
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => {
    if (type === SavedBrandStatus.sent) {
      const [newList, newCount] = _addToList(
        brand,
        collection.sentBrands,
        collection.sentCount,
      );
      collection.sentBrands = newList;
      collection.sentCount = newCount;
    } else if (type === SavedBrandStatus.unsent) {
      const [newList, newCount] = _addToList(
        brand,
        collection.unsentBrands,
        collection.unsentCount,
      );
      collection.unsentBrands = newList;
      collection.unsentCount = newCount;
    }
  };

  const _removeFromCollection = (
    bentoBrandId: number,
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => {
    if (type === SavedBrandStatus.sent) {
      const [newSentList, newSentCount] = _removeFromList(
        bentoBrandId,
        collection.sentBrands,
        collection.sentCount,
      );
      collection.sentBrands = newSentList;
      collection.sentCount = newSentCount;
    } else if (type === SavedBrandStatus.unsent) {
      const [newUnsentList, newUnsentCount] = _removeFromList(
        bentoBrandId,
        collection.unsentBrands,
        collection.unsentCount,
      );
      collection.unsentBrands = newUnsentList;
      collection.unsentCount = newUnsentCount;
    }
  };

  const _remove = (brand: SavedBrand, oneType?: SavedBrandStatus) => {
    setCollections((prev) => {
      const copy = { ...prev };
      for (const collection of Object.values(copy)) {
        if (oneType === undefined || oneType === SavedBrandStatus.sent) {
          _removeFromCollection(
            brand.bentoBrandId,
            collection,
            SavedBrandStatus.sent,
          );
        }
        if (oneType === undefined || oneType === SavedBrandStatus.unsent) {
          _removeFromCollection(
            brand.bentoBrandId,
            collection,
            SavedBrandStatus.unsent,
          );
        }
      }
      return copy;
    });
  };

  const _findInCollection = (
    bentoBrand: BentoBrand,
    currentCollections: SavedBrandCollectionsMap,
  ) => {
    let indexOfCustomCollection = undefined;
    let customCollectionId = undefined;
    let type = undefined;
    let indexOfAllSaved = undefined;

    for (const collection of Object.values(currentCollections)) {
      const foundSentResult = _findIndex(
        bentoBrand,
        collection.sentBrands || [],
      );
      if (foundSentResult > -1) {
        type = SavedBrandStatus.sent;
        if (collection.id === 0) {
          indexOfAllSaved = foundSentResult;
        } else {
          indexOfCustomCollection = foundSentResult;
          customCollectionId = collection.id;
        }
        continue;
      }
      const foundUnsentResult = _findIndex(
        bentoBrand,
        collection.unsentBrands || [],
      );
      if (foundUnsentResult > -1) {
        type = SavedBrandStatus.unsent;
        if (collection.id === 0) {
          indexOfAllSaved = foundUnsentResult;
        } else {
          indexOfCustomCollection = foundUnsentResult;
          customCollectionId = collection.id;
        }
        continue;
      }
    }

    if (
      indexOfAllSaved !== undefined &&
      type !== undefined &&
      !customCollectionId
    ) {
      customCollectionId =
        currentCollections[0][
          type === SavedBrandStatus.sent ? "sentBrands" : "unsentBrands"
        ]?.[indexOfAllSaved].bentoBrand.savedBrandCollectionId;
    }

    return {
      indexOfCustomCollection,
      customCollectionId,
      type,
      indexOfAllSaved,
    };
  };

  const _findIndex = (
    bentoBrand: BentoBrand,
    collectionBrands: SavedBrand[],
  ) => {
    return collectionBrands.findIndex(
      (sb) => Number(sb?.bentoBrandId) === Number(bentoBrand.id),
    );
  };

  const _modifyProperty = (
    collectionId: number,
    bentoBrand: BentoBrand,
    property: string, // a key such as 'hasOutreachDraft'
    value: any, // value for that key,
    addToList = true,
    listType = SavedBrandStatus.unsent,
  ) => {
    const {
      type,
      indexOfCustomCollection,
      customCollectionId,
      indexOfAllSaved,
    } = _findInCollection(bentoBrand, collections);

    if (!type && addToList) {
      updateCollection(collectionId, listType, {
        newBrands: (prevBrands) => {
          return [
            ...(prevBrands || []),
            {
              bentoBrand: { ...bentoBrand, [property]: value },
              bentoBrandId: bentoBrand.id,
              createdAt: getNow(),
            },
          ];
        },
        newCount: (prevCount) => prevCount + 1,
      });
    } else if (type) {
      setCollections((prev) => {
        const copy = { ...prev };
        const keyName =
          type === SavedBrandStatus.sent ? "sentBrands" : "unsentBrands";
        if (
          indexOfCustomCollection !== undefined &&
          indexOfCustomCollection > -1 &&
          customCollectionId !== undefined
        ) {
          // @ts-ignore
          copy[customCollectionId][keyName][indexOfCustomCollection][
            "bentoBrand"
          ][property] = value;
        }
        if (indexOfAllSaved !== undefined && indexOfAllSaved > -1) {
          // @ts-ignore
          copy[0][keyName][indexOfAllSaved]["bentoBrand"][property] = value;
        }
        return copy;
      });
    }
  };

  const _removeFromList = (
    bentoBrandId: number,
    brandList?: SavedBrand[],
    count?: number,
  ): [SavedBrand[], number] => {
    let newList = brandList || [];
    const inBrandListIndex = newList.findIndex(
      (b) => b.bentoBrandId === bentoBrandId,
    );
    let updatedCount = count || 0;
    if (inBrandListIndex >= 0) {
      newList = [
        ...newList.slice(0, inBrandListIndex),
        ...newList.slice(inBrandListIndex + 1),
      ];
      updatedCount = Math.max(0, updatedCount - 1);
    }
    return [newList, updatedCount];
  };

  const _addToList = (
    savedBrand: SavedBrand,
    brandList?: SavedBrand[],
    count?: number,
    originalIndex?: number,
  ): [SavedBrand[], number] => {
    const inBrandListIndex = brandList?.findIndex(
      (b) => b.bentoBrandId === savedBrand.bentoBrandId,
    );
    let newList = brandList || [];
    let updatedCount = count || 0;
    if (inBrandListIndex === undefined || inBrandListIndex < 0) {
      if (originalIndex !== undefined) {
        newList = [
          ...newList.slice(0, originalIndex),
          savedBrand,
          ...newList.slice(originalIndex),
        ];
      } else {
        newList = [savedBrand, ...newList];
      }
      updatedCount += 1;
    } else {
      newList[inBrandListIndex] = savedBrand;
    }
    return [newList, updatedCount];
  };

  return {
    updateTotal,
    isSavedBrand,
    modifySavedBrands,
    addDraftToCollection,
    removeDraftFromCollection,
    isDraftBrand,
    createOrUpdateCollection,
    deleteCollection,
    bulkMoveToSent,
    addToCollectionTab,
    getTotal,
  };
};
