import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "../styles";

interface CustomizePartTwoEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

const CustomizePartTwoEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: CustomizePartTwoEmailFormProps) => {
  const location = useLocation();

  const addIdea = () => {
    trackEvent("Onboarding Form - Add Idea Button Pressed");
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        uniqueContentIdeas: [...(prev.uniqueContentIdeas || ["", "", ""]), ""],
      };
      return copy;
    });
  };

  const removeIdea = (index: number) => {
    trackEvent("Onboarding Form - Remove Idea Button Pressed");
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        uniqueContentIdeas: (prev.uniqueContentIdeas || ["", "", ""]).filter(
          (_: string, i: number) => i !== index,
        ),
      };
      return copy;
    });
  };

  const handleIdeaChange = (newIdea: string, index: number) => {
    setOnboardingForm((prev) => {
      const copy = { ...prev };
      if (!copy.uniqueContentIdeas) {
        copy.uniqueContentIdeas = ["", "", ""];
      }
      copy.uniqueContentIdeas = copy.uniqueContentIdeas.map(
        (idea: string, idx: number) => {
          if (idx === index) {
            return newIdea;
          }
          return idea;
        },
      );
      return copy;
    });
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3, textAlign: "center", mt: 4 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-envelope"
          />
          Let us <i>really</i> help you customize every email
        </Typography>
        <Grid sx={styles.fieldContainer}>
          <FormHelperText sx={styles.helperText}>
            Share 3-4 recent ideas you've pitched to brands
          </FormHelperText>

          <Grid container rowSpacing={0.5} sx={{ mb: 0.5 }}>
            {(onboardingForm.uniqueContentIdeas || ["", "", ""]).map(
              (idea: string, index: number) => (
                <Grid
                  item
                  alignItems="center"
                  container
                  columnGap={0.5}
                  xs={12}
                  key={index}
                >
                  <Grid item xs>
                    <TextField
                      sx={[styles.textField, { mb: 0 }]}
                      fullWidth
                      placeholder={`Idea #${index + 1}`}
                      onChange={(e: CustomEvent) => {
                        handleIdeaChange(e.target.value, index);
                      }}
                      value={idea || ""}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      onClick={() => {
                        removeIdea(index);
                      }}
                      sx={styles.removeButton}
                    >
                      <Box
                        component="i"
                        className="fa-regular fa-circle-xmark"
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>

          <Button size="small" onClick={addIdea} variant="outlined">
            Add Idea
            <Box component="i" className="fa-solid fa-add" sx={{ ml: 1 }} />
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default CustomizePartTwoEmailForm;
