import { useAuth } from "@clerk/clerk-react";
import { inRange } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  OutreachDraft,
  PreviewMessage,
} from "schemas/dashboard";
import { HandleFetchPreviewFunction } from "schemas/outreach";
import { routes } from "schemas/routes";

import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { isPage } from "utils/general";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { OutreachTemplatesContext } from "./OutreachTemplates";
import { QuickSendContactsContext } from "./QuickSendContacts";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import { QuickSendOutreachTabsContext } from "./QuickSendOutreachTabs";
import { SubscriptionContext } from "./Subscription";
import {
  draftToPreview,
  getContactName,
  getDraftTemplates,
  getEmailTemplates,
} from "./helpers/QuickSendHelpers";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

export type LastContactedDate = Date | null;

interface QuickSendContextInterface {
  templateForQuickSend: Template[];
  setTemplateForQuickSend: Dispatch<SetStateAction<Template[]>>;
  handleFetchPreviews: HandleFetchPreviewFunction;
  handleRefetchPreviews: (
    templateForQuickSend: Template[],
    contactEmail?: string,
  ) => void;
  setNewlyAddedTemplateIndex: Dispatch<SetStateAction<number>>;
  newlyAddedTemplateIndex: number;
  bentoContact: BentoContact | null;
  bentoBrand: BentoBrand | null;
  outreachMessages: PreviewMessage[];
  setOutreachMessages: Dispatch<SetStateAction<PreviewMessage[]>>;
  setBentoContact: Dispatch<SetStateAction<BentoContact | null>>;
  templatesToUpdate: number[];
  setTemplatesToUpdate: Dispatch<SetStateAction<number[]>>;
  contactName?: string;
  clearDraftTemplates: () => Template[];
  errorWhenFetchingPreview: boolean;
}

const defaultInterface = {
  templateForQuickSend: [],
  setTemplateForQuickSend: defaultContextMissingFunction,
  handleFetchPreviews: defaultContextMissingFunction,
  handleRefetchPreviews: defaultContextMissingFunction,
  setNewlyAddedTemplateIndex: defaultContextMissingFunction,
  newlyAddedTemplateIndex: -1,
  bentoContact: null,
  newContact: null,
  bentoBrand: null,
  outreachMessages: [],
  setOutreachMessages: defaultContextMissingFunction,
  setBentoContact: defaultContextMissingFunction,
  templatesToUpdate: [],
  setTemplatesToUpdate: defaultContextMissingFunction,
  contactName: "",
  clearDraftTemplates: defaultContextMissingFunction,
  errorWhenFetchingPreview: false,
};

const QuickSendContext =
  createContext<QuickSendContextInterface>(defaultInterface);

interface QuickSendProviderProps {
  children: React.ReactNode;
}

const QuickSendProvider = ({ children }: QuickSendProviderProps) => {
  // Contexts
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { outreachTab, setOutreachTab } = useContext(
    QuickSendOutreachTabsContext,
  );
  const { getPreviewContacts, updateBentoContacts } = useContext(
    QuickSendContactsContext,
  );
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setUpgradeDialogSource } = useContext(SubscriptionContext);
  const { defaultTemplates, templates, setTemplates, fetchOutreachTemplates } =
    useContext(OutreachTemplatesContext);
  const { bentoContacts } = useContext(QuickSendContactsContext);
  const { sendFrom } = useContext(QuickSendDrawerContext);
  const { setPreviewLoading } = useContext(QuickSendDrawerContext);

  // States
  const [bentoBrand, setBentoBrand] = useState<BentoBrand | null>(null);
  const [bentoContact, setBentoContact] = useState<
    BentoContact | NewBentoContact | null
  >(null);
  const [outreachMessages, setOutreachMessages] = useState<PreviewMessage[]>(
    [],
  );
  const [templateForQuickSend, setTemplateForQuickSend] = useState<Template[]>(
    [],
  );
  const [newlyAddedTemplateIndex, setNewlyAddedTemplateIndex] =
    useState<number>(-1);
  const [templatesToUpdate, setTemplatesToUpdate] = useState<number[]>([]);
  const [errorWhenFetchingPreview, setErrorWhenFetchingPreview] =
    useState<boolean>(false);
  const contactName = getContactName(bentoBrand, bentoContact);

  const handleRefetchPreviews = async (
    templateForQuickSend: Template[],
    contactEmail?: string,
  ) => {
    if (!bentoBrand?.id) {
      return;
    }
    const selectedNewContact = bentoContacts?.find(
      (x) => x.email === contactEmail,
    );
    await handleFetchPreviews(
      bentoBrand?.id,
      selectedNewContact || bentoContact,
      templateForQuickSend,
    );
  };

  const handleFetchPreviews = async (
    brandId: number,
    specificContact?: BentoContact | NewBentoContact | null,
    specificTemplate?: Template[],
    fetchDraft: boolean = false,
    updatedTemplates?: Template[],
  ): Promise<boolean> => {
    if (!currentOrg?.id || !brandId) return false;

    let hasDraft = false;

    const resetBentoContacts = bentoBrand?.id !== brandId;
    const { contacts, data } = await getPreviewContacts(
      brandId,
      {
        bentoBrandId: brandId,
        emailTemplates: [],
      },
      specificContact,
      resetBentoContacts,
    );

    const emailTemplates = getEmailTemplates(
      setTemplateForQuickSend,
      specificTemplate,
      templateForQuickSend,
      defaultTemplates,
    );

    data["emailTemplates"] = emailTemplates;

    if (contactName) {
      data["previousContactName"] = contactName;
    }
    if (sendFrom) {
      data["source"] = sendFrom;
    }
    if (fetchDraft) {
      data.fetchDraft = true;
    }
    if (!templates) {
      fetchOutreachTemplates();
    }

    const res = await fetcherAuth(
      getToken,
      `/api/organization/${currentOrg?.id}/outreach/preview-quick-send`,
      "POST",
      {},
      data,
      true,
      false,
      true,
    );
    setPreviewLoading(-1);

    if (res.ok) {
      setErrorWhenFetchingPreview(false);
      const json = await res.json();
      const { previewContact, outreachMessages, bentoBrand, outreachDraft } =
        json.preview;

      if (fetchDraft && outreachDraft) {
        hasDraft = true;
        addDraftTemplates(
          outreachDraft,
          updatedTemplates || templates,
          previewContact,
        );
      } else {
        setBentoContact(previewContact);
        setOutreachMessages(outreachMessages);
        const templateMessages = outreachMessages.map(
          (message: PreviewMessage) => {
            return {
              ...message,
              id: message.templateId,
              name: message.templateName,
            };
          },
        );
        setTemplateForQuickSend(templateMessages);
      }

      // Makes sure outreachTab index is always in range with the list of outreach messages
      const isInRange = inRange(outreachTab, 0, outreachMessages?.length);
      if (!isInRange) {
        setOutreachTab(0);
      }
      setBentoBrand(bentoBrand);
      updateBentoContacts(
        contacts,
        previewContact,
        Boolean(resetBentoContacts),
      );
      return hasDraft;
    } else if (res.status === 403) {
      setUpgradeDialogSource("Hits Limit");
      if (isPage(routes.brands)) {
        navigate(`/${routes.brands}`);
      } else {
        navigate(-1);
      }
    } else if (res.status === 404) {
      setAlert("Brand not found", "error");
      navigate("/");
    } else {
      setErrorWhenFetchingPreview(true);
      const json = await res.json();
      setAlert(
        json.message ||
          "Unable to fetch previews. Please try again. Contact hello@onbento.com for assistance if the problem persists.",
        "error",
      );
    }
    return false;
  };

  const addDraftTemplates = (
    outreachDraft: OutreachDraft,
    templates: Template[],
    previewContact: BentoContact | NewBentoContact,
  ) => {
    setBentoContact(previewContact);

    const draftTemplates = getDraftTemplates(outreachDraft, templates);
    setTemplateForQuickSend(draftTemplates);
    // clear any existing draft templates
    const updatedTemplates = templates?.filter((x) => x.id && !isNaN(x.id));
    const names = updatedTemplates?.map((x: Template) => x.name);
    for (const template of draftTemplates) {
      if (!names?.includes(template?.name)) {
        updatedTemplates.push(template);
        names.push(template.name);
      }
    }
    setTemplates(updatedTemplates);

    const to = previewContact.email;
    if (to) {
      const newOutreachMessages = outreachDraft?.emailDrafts.map(
        (draft, index) => draftToPreview(draft, to, draftTemplates[index]),
      );
      if (newOutreachMessages) {
        setOutreachMessages(newOutreachMessages);
      }
    }
  };

  const clearDraftTemplates = () => {
    // When user exited the QuickSend drawer, remove all the draft templates.
    const updatedTemplates = templates.filter((x) => x.id && !isNaN(x.id));
    setTemplates(updatedTemplates);
    return updatedTemplates;
  };

  return (
    <QuickSendContext.Provider
      value={{
        templateForQuickSend,
        setTemplateForQuickSend,
        handleFetchPreviews,
        setNewlyAddedTemplateIndex,
        newlyAddedTemplateIndex,
        handleRefetchPreviews,
        bentoContact,
        bentoBrand,
        outreachMessages,
        setOutreachMessages,
        setBentoContact,
        templatesToUpdate,
        setTemplatesToUpdate,
        contactName,
        clearDraftTemplates,
        errorWhenFetchingPreview,
      }}
    >
      {children}
    </QuickSendContext.Provider>
  );
};

export { QuickSendProvider, QuickSendContext };
