import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SubscriptionType } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CALENDLY_LINK = " https://calendly.com/jaclyn-bento/bento-onboarding";

const UpgradeDialog = ({ open, handleClose }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const [searchParams] = useSearchParams();
  const { subscription, isFreePlan } = useContext(SubscriptionContext);
  const { setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const checkoutSuccess = searchParams.get("checkout") === "success";

  const isSubscribed =
    subscription?.subscription?.status === SubscriptionType.ACTIVE &&
    !isFreePlan;

  const handleCheckout = async () => {
    trackEvent("Book Call Button Clicked");
    setLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/checkout`,
        "POST",
        {},
        {
          plan: Plans.PRO,
          flow: "subscription_update",
          source: "Onboarding Video",
        },
      );
      window.open(res.session.url, "_self");
    } catch (error) {
      setAlert(
        <>
          Unable to create checkout session. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists
        </>,
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const goToCall = () => {
    trackEvent("Book Onboarding Calendly Link Clicked");
    window.open(CALENDLY_LINK, "_blank");
  };

  useEffect(() => {
    if (open && (isSubscribed || checkoutSuccess)) {
      trackEvent("Take your Pitching To Next Level Dialog Shown");
    } else if (open) {
      trackEvent("Upgrade Offer Dialog Shown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, checkoutSuccess]);

  if (isSubscribed || checkoutSuccess) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ fontSize: { xs: 13, md: 16 } }}
          >
            Take Your Pitching to Next Level ✨
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography>
            {checkoutSuccess &&
              "We're so glad to have you part of the Bento community 🙌."}{" "}
            Schedule a 1:1 call with Bento's CEO by clicking the link below, and
            we will help you craft a personalized pitch email in 30 minutes.
            <Box sx={{ my: 2 }}>
              Book call:{" "}
              <Link
                sx={{
                  color: "info.main",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={goToCall}
              >
                <strong>{CALENDLY_LINK}</strong>
              </Link>
            </Box>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6" textAlign="center">
            Upgrade Offer: Pay $20 and receive:
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 2 }} textAlign="center">
            1. Get Bento's CEO to rewrite your pitch email so you get more
            responses
          </Typography>

          <Typography sx={{ mb: 2 }} textAlign="center">
            2. 1 month of Bento's Pro account (send 1000 emails per month)
          </Typography>

          <Grid
            container
            justifyContent="center"
            sx={{ my: 4 }}
            onClick={handleCheckout}
          >
            <LoadingButton
              loading={loading}
              variant="contained"
              disableElevation
            >
              Book Call
            </LoadingButton>
          </Grid>

          <Typography>
            Note: For <strong>newer creators</strong> this Offer is even more
            relevant to you. As a new creator without a track record, writing a
            catchy and unique email is the only way you will stand out!
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
};

export default UpgradeDialog;
