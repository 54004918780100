import { useAuth } from "@clerk/clerk-react";
import { Grid, Link } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext, SubscriptionTab } from "contexts/Subscription";
import { useContext, useState } from "react";
import { SetAlertType } from "schemas/functions";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "utils/api";

import { Plan } from "./Plan";

interface IProps {
  setAlert: SetAlertType;
}

type LoadingState = {
  [key in Plans]: boolean;
};

export default function Subscription({ setAlert }: IProps) {
  const { getToken } = useAuth();
  const { plans, setSubscription, subscription, tab } =
    useContext(SubscriptionContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const [loading, setLoading] = useState<LoadingState>({
    [Plans.FREE]: false,
    [Plans.PRO]: false,
    [Plans.STARTER]: false,
    [Plans.STARTER_YEARLY]: false,
    [Plans.PRO_YEARLY]: false,
    [Plans.VA]: false,
    [Plans.VA_YEARLY]: false,
  });

  const renewSubscription = async (plan: Plans) => {
    setLoading((l) => ({ ...l, [plan]: true }));
    try {
      const { subscription } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/renew`,
        "POST",
        {},
      );
      setSubscription(subscription);
      setAlert("Your plan has been successfully renewed!", "success");
    } catch (error) {
      setAlert(
        <>
          Unable to renew subscription. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists.
        </>,
        "error",
      );
    } finally {
      setLoading((l) => ({ ...l, [plan]: false }));
    }
  };

  const handleCheckout = async (
    plan: Plans,
    flow: string | undefined = undefined,
    openTarget: "_self" | "_blank" = "_self",
  ) => {
    setLoading((l) => ({ ...l, [plan]: true }));
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/checkout`,
        "POST",
        {},
        {
          plan,
          flow,
          source: "Upgrade Dialog",
        },
      );
      window.open(res.session.url, openTarget);
    } catch (error) {
      setAlert(
        <>
          Unable to create checkout session. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists
        </>,
        "error",
      );
    } finally {
      setLoading((l) => ({ ...l, [plan]: false }));
    }
  };

  const getAllPlans = () => {
    if (tab === SubscriptionTab.yearly) {
      const yearly = [
        Plans.FREE,
        Plans.STARTER_YEARLY,
        Plans.PRO_YEARLY,
        Plans.VA_YEARLY,
      ];
      return yearly;
    } else {
      const monthly = [Plans.FREE, Plans.STARTER, Plans.PRO];
      if (subscription?.subscription?.planName === Plans.VA) {
        return [...monthly, Plans.VA];
      }
      return monthly;
    }
  };

  return (
    <Grid container alignItems="stretch" spacing={4}>
      {plans &&
        Object.keys(plans)?.length > 0 &&
        getAllPlans()?.map((plan) => (
          <Plan
            key={plan}
            plan={plan as Plans}
            loading={loading[plan as Plans]}
            handleCheckout={handleCheckout}
            renewSubscription={renewSubscription}
            allLoading={loading}
          />
        ))}
    </Grid>
  );
}
