import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizeCategoryProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
}

const CustomizeCategoryEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
}: CustomizeCategoryProps) => {
  const location = useLocation();

  const handleChange = (category: string) => (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (!copy.categorySpecificDetails) {
      copy.categorySpecificDetails = {};
    }
    if (!copy.categorySpecificDetails[category]) {
      copy.categorySpecificDetails[category] = {};
    }
    copy.categorySpecificDetails[category][name] = value;
    setOnboardingForm(copy);
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container sx={styles.fieldContainer} rowGap={2}>
          {(onboardingForm.categories || []).map((category: string) => (
            <Grid item xs={12} key={category}>
              <Typography paragraph>
                For pitching to brands in <strong>{category}</strong>, share:
              </Typography>
              <Box sx={{ mx: 1 }}>
                <FormHelperText sx={styles.helperText}>
                  What's something unique about yourself that will help you
                  stand out to brands in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="about_me"
                  placeholder="I absolutely love ..."
                  onChange={handleChange(category)}
                  multiline
                  minRows={2}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.about_me || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Any past brands you've worked with in {category}:
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="past_brands"
                  placeholder="Share brand names ..."
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.past_brands || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Can you share a link to a sample video in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="sample_video"
                  placeholder="Enter social media link"
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.sample_video || ""
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>
    </>
  );
};

export default CustomizeCategoryEmailForm;
