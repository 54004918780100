import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BentoBrand, OnboardAction } from "schemas/dashboard";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import BrandRecommendationCard from "components/BrandRecommendationCard";
import { trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface SelectBrandFormProps {
  onboardingForm: Map;
  selectedBrandId?: number;
  setSelectedBrandId: Dispatch<SetStateAction<number | undefined>>;
  recommendedBrands: BentoBrand[];
  refreshBrands: (restart: boolean) => void;
  enqueueGenerateRecommendations: () => void;
  loading: boolean;
}

const SelectBrandForm = ({
  onboardingForm,
  selectedBrandId,
  setSelectedBrandId,
  refreshBrands,
  enqueueGenerateRecommendations,
  recommendedBrands,
  loading,
}: SelectBrandFormProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { trackUserHasOnboarded } = useTracking();
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const handleSkip = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "selectBrandForm",
    });

    trackUserHasOnboarded(OnboardAction.SKIP_SELECTING_BRANDS);
    navigate(`/${routes.brands}/all${location.search}`);
  };

  const handleSubmit = async (e: CustomEvent) => {
    e.preventDefault();

    trackEvent("Onboarding Form - Next Button Pressed", {
      page: "selectBrandForm",
    });

    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }

    navigate(`/${routes.emailOnboarding}/${selectedBrandId}${location.search}`);
  };

  useEffect(() => {
    refreshBrands(true);
    enqueueGenerateRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const handleClickCard = (bentoBrand: BentoBrand) => {
    trackEvent("Onboarding Form - Brand Card Selected", {
      "Bento Brand ID": bentoBrand?.id,
    });
    setSelectedBrandId(bentoBrand.id);
  };

  const handleRefreshBrands = () => {
    trackEvent("Onboarding Form - Refresh Brands Button Clicked");
    refreshBrands(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-paper-plane"
          />
          While we're customizing your dashboard, let's send your first email!
        </Typography>

        {loading && (
          <Grid container sx={styles.fieldContainer} spacing={1}>
            {Array.from({ length: 6 }, (x, i) => (
              <Grid item key={i} xs={6} md={4}>
                <Skeleton variant="rounded" sx={styles.loadingSkeleton} />
              </Grid>
            ))}
          </Grid>
        )}

        {!loading && (
          <Grid container sx={styles.fieldContainer} spacing={1}>
            {recommendedBrands.map((bentoBrand, idx) => (
              <Grid item xs={6} md={4} key={bentoBrand.id}>
                <BrandRecommendationCard
                  index={idx}
                  fullWidth
                  selected={bentoBrand.id === selectedBrandId}
                  showActions={false}
                  handleClickCard={handleClickCard}
                  recommendation={{
                    id: -1,
                    source: null,
                    bentoBrand,
                    bentoBrandId: bentoBrand.id,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container justifyContent="flex-end" sx={styles.fieldContainer}>
          <Button color="inherit" onClick={handleRefreshBrands}>
            <Box
              component="i"
              className="fa-solid fa-arrows-rotate"
              sx={{ mr: 1 }}
            />
            Refresh
          </Button>
        </Grid>

        <Grid item xs="auto" container columnGap={1}>
          <Grid item xs="auto">
            <Button onClick={handleBack} disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="secondary" onClick={handleSkip} disableElevation>
              Skip
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <LoadingButton
              disableElevation
              variant="contained"
              type="submit"
              disabled={!selectedBrandId}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SelectBrandForm;
