import { Box, Button, Grid, Typography } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { OnboardAction } from "schemas/dashboard";
import { routes } from "schemas/routes";

import QuickSendIndividualDrawer from "features/Influencer/QuickSendIndividualDrawer";
import { trackEvent } from "utils/tracking";
import { useTracking } from "utils/useTracking";

import styles from "../styles";
import DraftLoader from "./DraftLoader";

interface IProps {
  loading: boolean;
  fetchEmailPreview: (bentoBrandId: number) => void;
}

export default function EmailBrandForm({ loading, fetchEmailPreview }: IProps) {
  const { brandId } = useParams();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { trackUserHasOnboarded } = useTracking();
  const bentoBrandId = Number(brandId);
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const { fetchOutreachTemplates } = useContext(OutreachTemplatesContext);

  useEffect(() => {
    fetchEmailPreview(bentoBrandId);
    if (currentOrg?.id) {
      fetchOutreachTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bentoBrandId, currentOrg?.id]);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(`/${routes.selectBrandOnboarding}${location.search}`);
  };

  const handleSkip = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "emailBrandForm",
    });

    trackUserHasOnboarded(OnboardAction.SKIP_EMAILING_BRANDS);
    navigate(`/${routes.brands}/all${location.search}`);
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 3 }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-paper-plane"
        />
        Make any edits to this email!
      </Typography>
      <Box sx={styles.quickSendWrapper}>
        {loading && (
          <Box sx={styles.loaderWrapper}>
            <DraftLoader />
          </Box>
        )}
        {!loading && <QuickSendIndividualDrawer />}
      </Box>

      <Grid item xs="auto" container columnGap={1}>
        <Grid item xs="auto">
          <Button onClick={handleBack} disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button onClick={handleSkip} disableElevation color="secondary">
            Skip
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
