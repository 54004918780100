import { SignIn, SignUp, useAuth } from "@clerk/clerk-react";
import { Box, Button, Typography } from "@mui/material";
import powerByLogo from "assets/logos/logo-transparent.png";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "schemas/routes";

import Loading from "components/Loading";
import { LANDING_PAGE_LINK } from "constants/influencer";
import { getNativeBrowserLink, isWebView } from "utils/device";

import InfluencerLayout from "./Layout/Influencer";
import { AuthAction } from "./schema";
import styles from "./styles";

interface AuthPageProps {
  action: AuthAction;
  prevLink: string;
}

const AuthPage = ({ action, prevLink }: AuthPageProps) => {
  const { isLoaded, isSignedIn } = useAuth();
  const location = useLocation();
  const isSignedUpForVa = location.pathname.includes("/va");

  const redirectUrl = prevLink?.includes(routes.dashboard)
    ? prevLink
    : isSignedUpForVa
      ? `/${routes.onboardingVaInfluencer}${location.search}`
      : `/${routes.onboardingInfluencer}${location.search}`;

  if (window.location.hostname === "crm.hatchways.io") {
    const newPath = "https://app.onbento.com" + window.location.pathname;
    window.location.href = newPath;
    return null;
  }

  if (isSignedIn) {
    return <Navigate to={redirectUrl} replace />;
  }

  if (isLoaded) {
    if (isWebView()) {
      return (
        <InfluencerLayout>
          <Box sx={styles.form}>
            <Box component="img" src={powerByLogo} sx={styles.poweredByLogo} />
            <Typography variant="h5" sx={styles.title}>
              Join Bento
            </Typography>
            <Typography sx={styles.subtitle}>
              Click the button below to login or create a new account
            </Typography>
            <a href={getNativeBrowserLink()} target="_blank" rel="noreferrer">
              <Button sx={styles.button} variant="contained" disableElevation>
                Access Bento{" "}
                <Box
                  component="i"
                  className="fa-regular fa-arrow-right"
                  sx={{ ml: 1 }}
                />
              </Button>
            </a>
          </Box>
        </InfluencerLayout>
      );
    } else {
      return (
        <InfluencerLayout>
          {action === AuthAction.SIGN_UP && (
            <SignUp
              signInUrl={
                isSignedUpForVa ? `/${routes.signIn}/va` : `/${routes.signIn}`
              }
              redirectUrl={redirectUrl}
              appearance={{
                layout: {
                  logoLinkUrl: LANDING_PAGE_LINK,
                },
              }}
            />
          )}
          {action === AuthAction.SIGN_IN && (
            <SignIn
              signUpUrl={
                isSignedUpForVa ? `/${routes.signUpVa}` : `/${routes.signUp}`
              }
              redirectUrl={redirectUrl}
              appearance={{
                layout: {
                  logoLinkUrl: LANDING_PAGE_LINK,
                },
              }}
            />
          )}
        </InfluencerLayout>
      );
    }
  } else {
    return <Loading />;
  }
};

export default AuthPage;
