import { ClerkProvider, SignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AdminUserProvider } from "contexts/Admin";
import { AlertProvider } from "contexts/Alert";
import { AutogeneratedDraftsProvider } from "contexts/AutogenerateDrafts";
import { AutomationsProvider } from "contexts/Automations";
import { BrandsProvider } from "contexts/Brands";
import { ChatHelperProvider } from "contexts/ChatHelper";
import { ContactViewProvider } from "contexts/ContactView";
import { ContactViewSearchProvider } from "contexts/ContactViewSearch";
import { DiscoverViewProvider } from "contexts/DiscoverView";
import { OrganizationUserProvider } from "contexts/Organization";
import { OutreachContactsProvider } from "contexts/OutreachContacts";
import { OutreachTemplatesProvider } from "contexts/OutreachTemplates";
import { QuickSendProvider } from "contexts/QuickSend";
import { QuickSendContactsProvider } from "contexts/QuickSendContacts";
import { QuickSendDrawerProvider } from "contexts/QuickSendDrawer";
import { QuickSendEditorProvider } from "contexts/QuickSendEditor";
import { QuickSendErrorProvider } from "contexts/QuickSendError";
import { QuickSendOutreachTabsProvider } from "contexts/QuickSendOutreachTabs";
import { QuickSendPaginationProvider } from "contexts/QuickSendPagination";
import { SavedBrandProvider } from "contexts/SavedBrand";
import { SavedBrandCollectionsProvider } from "contexts/SavedBrandCollections";
import { SubscriptionProvider } from "contexts/Subscription";
import { TasksProvider } from "contexts/Tasks";
import { TopRecommendationsProvider } from "contexts/TopRecommendations";
import { TrackingColumnsProvider } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersProvider } from "contexts/TrackingColumnsFilters";
import { TrackingColumnsSortProvider } from "contexts/TrackingColumnsSort";
import { UserIntegrationsProvider } from "contexts/UserIntegrations";
import { UserRequestsProvider } from "contexts/UserRequests";
import { ReactNode, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { AdminHome } from "features/Admin";
import AdminBrands from "features/Admin/Brand";
import AdminContacts from "features/Admin/Contact";
import AdminCsvUpload from "features/Admin/CsvUpload";
import AdminElasticsearch from "features/Admin/Elasticsearch";
import AdminMigrations from "features/Admin/Migrations";
import AdminOrganization from "features/Admin/Organization";
import AdminReadEmail from "features/Admin/ReadEmail";
import AdminReferrals from "features/Admin/Referrals";
import AdminScraping from "features/Admin/Scraping";
import UserRequests from "features/Admin/UserRequests";
import AuthPage from "features/Auth";
import Layout from "features/Auth/Layout";
import OnboardingInfluencer from "features/Auth/Onboarding";
import OnboardingVaInfluencer from "features/Auth/VAOnboarding";
import { AuthAction } from "features/Auth/schema";
import Home from "features/Home";
import InfluencerContacts from "features/Influencer/ContactList";
import Discover from "features/Influencer/Discover";
import DiscoverResults from "features/Influencer/Discover/Results";
import PortfolioSitesPage from "features/Influencer/PortfolioSites";
import Profile from "features/Influencer/Profile";
import RecommendationsPage from "features/Influencer/Recommendations";
import TemplatesPage from "features/Influencer/Templates";
import InfluencerTracking from "features/Influencer/Tracking";
import LandingPage from "features/Landing";
import PrivacyPolicyPage from "features/PrivacyPolicy";
import Settings from "features/Settings";
import PageNotFound from "pages/PageNotFound";
import { trackEvent } from "utils/tracking";

import "./App.css";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

if (!process.env.REACT_APP_ADMIN_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Admin Publishable Key");
}

if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error("Missing Google Client ID");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const adminClerkPubKey = process.env.REACT_APP_ADMIN_CLERK_PUBLISHABLE_KEY;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  const [trackedInitial, setTrackedInitial] = useState(false);

  useEffect(() => {
    const trackVisit = () => {
      if (!trackedInitial) {
        trackEvent("App Visited");
        setTrackedInitial(true);
      }
    };
    trackVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackedInitial]);

  return <AppRoutes />;
}

interface ClerkProviderWrapperProps {
  children: ReactNode;
  pubKey: string;
}

const ClerkProviderWrapper: React.FC<ClerkProviderWrapperProps> = ({
  children,
  pubKey,
}) => {
  const localization = {
    signUp: {
      start: {
        subtitle:
          "Note: If you do not use a Gmail account, you will have limited access to Bento's product.",
      },
    },
  };

  return (
    <ClerkProvider
      publishableKey={pubKey}
      appearance={{
        variables: {
          colorPrimary: "#014339",
          colorText: "#151e16",
        },
      }}
      localization={localization}
    >
      {children}
    </ClerkProvider>
  );
};

const DashboardProviderWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <ClerkProviderWrapper pubKey={clerkPubKey}>
      <OrganizationUserProvider>
        <AlertProvider>
          <GoogleOAuthProvider clientId={googleClientId}>
            <UserIntegrationsProvider>
              <SubscriptionProvider>
                <DashboardPagesWrapper>
                  <QuickSendWrapper>
                    <ChatHelperProvider>
                      <TrackingPagesWrapper>{children}</TrackingPagesWrapper>
                    </ChatHelperProvider>
                  </QuickSendWrapper>
                </DashboardPagesWrapper>
              </SubscriptionProvider>
            </UserIntegrationsProvider>
          </GoogleOAuthProvider>
        </AlertProvider>
      </OrganizationUserProvider>
    </ClerkProviderWrapper>
  );
};

const QuickSendWrapper = ({ children }: { children: ReactNode }) => {
  // Contexts needed for QuickSend
  return (
    <QuickSendDrawerProvider>
      <QuickSendPaginationProvider>
        <QuickSendContactsProvider>
          <QuickSendOutreachTabsProvider>
            <QuickSendErrorProvider>
              <QuickSendProvider>
                <QuickSendEditorProvider>{children}</QuickSendEditorProvider>
              </QuickSendProvider>
            </QuickSendErrorProvider>
          </QuickSendOutreachTabsProvider>
        </QuickSendContactsProvider>
      </QuickSendPaginationProvider>
    </QuickSendDrawerProvider>
  );
};

const DashboardPagesWrapper = ({ children }: { children: ReactNode }) => {
  // Contexts needed for pages inside Dashboard that are not dependent on QuickSend
  return (
    <OutreachTemplatesProvider>
      <BrandsProvider>
        <TopRecommendationsProvider>
          <AutogeneratedDraftsProvider>
            <DiscoverViewProvider>
              <SavedBrandCollectionsProvider>
                <SavedBrandProvider>
                  <TasksProvider>
                    <OutreachContactsProvider>
                      <UserRequestsProvider>
                        <AutomationsProvider>{children}</AutomationsProvider>
                      </UserRequestsProvider>
                    </OutreachContactsProvider>
                  </TasksProvider>
                </SavedBrandProvider>
              </SavedBrandCollectionsProvider>
            </DiscoverViewProvider>
          </AutogeneratedDraftsProvider>
        </TopRecommendationsProvider>
      </BrandsProvider>
    </OutreachTemplatesProvider>
  );
};

const TrackingPagesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <ContactViewProvider>
      <ContactViewSearchProvider>
        <TrackingColumnsProvider>
          <TrackingColumnsFiltersProvider>
            <TrackingColumnsSortProvider>
              {children}
            </TrackingColumnsSortProvider>
          </TrackingColumnsFiltersProvider>
        </TrackingColumnsProvider>
      </ContactViewSearchProvider>
    </ContactViewProvider>
  );
};

function AdminPage() {
  return (
    <AlertProvider>
      <SignedIn>
        <AdminUserProvider>
          <AdminHome />
        </AdminUserProvider>
      </SignedIn>
    </AlertProvider>
  );
}

function AppRoutes() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <DashboardProviderWrapper>
          <SignedIn>
            <Navigate to="/dashboard/brands/all" replace />
          </SignedIn>
          <SignedOut>
            <LandingPage />
          </SignedOut>
        </DashboardProviderWrapper>
      ),
      errorElement: <PageNotFound />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "sign-in/*",
      element: (
        <ClerkProviderWrapper pubKey={clerkPubKey}>
          <OrganizationUserProvider>
            <AlertProvider>
              <AuthPage
                action={AuthAction.SIGN_IN}
                prevLink={window.location.href}
              />
            </AlertProvider>
          </OrganizationUserProvider>
        </ClerkProviderWrapper>
      ),
    },
    {
      path: "sign-up/*",
      element: (
        <ClerkProviderWrapper pubKey={clerkPubKey}>
          <OrganizationUserProvider>
            <AlertProvider>
              <AuthPage
                action={AuthAction.SIGN_UP}
                prevLink={window.location.href}
              />
            </AlertProvider>
          </OrganizationUserProvider>
        </ClerkProviderWrapper>
      ),
    },
    {
      path: "/start/sign-in/*",
      element: <Navigate to="/sign-in" replace />,
    },
    {
      path: "/start/sign-up/*",
      element: <Navigate to="/sign-up" replace />,
    },
    {
      path: "/",
      element: (
        <DashboardProviderWrapper>
          <SignedIn>
            <Outlet />
          </SignedIn>
          <SignedOut>
            <Navigate to="/sign-in" replace />
          </SignedOut>
        </DashboardProviderWrapper>
      ),
      children: [
        {
          path: "/start-onboarding/*",
          element: <OnboardingInfluencer />,
        },
        {
          path: "/va-onboarding/*",
          element: <OnboardingVaInfluencer />,
        },
        {
          path: "/dashboard",
          element: <Home />,
          children: [
            {
              index: true,
              element: <Navigate to="/dashboard/brands/all" replace />,
            },
            {
              path: "inbox/*",
              element: <InfluencerContacts />,
            },
            {
              path: "recommendations/*",
              element: <RecommendationsPage />,
            },
            {
              path: "brands",
              element: <Discover />,
              children: [
                {
                  index: true,
                  element: <Navigate to="/dashboard/brands/all" replace />,
                },
                {
                  path: "all/*",
                  element: <DiscoverResults />,
                },
              ],
            },
            {
              path: "profile",
              element: <Profile />,
            },
            // TODO: Remove this when we no longer has emails using `discover`
            {
              path: "discover",
              element: <Discover />,
              children: [
                {
                  index: true,
                  element: <Navigate to="/dashboard/brands/all" replace />,
                },
                {
                  path: "all/*",
                  element: <DiscoverResults />,
                },
              ],
            },
            {
              path: "templates/*",
              element: <TemplatesPage />,
            },
            {
              path: "portfolio-sites/*",
              element: <PortfolioSitesPage />,
            },
            {
              path: "tracking/*",
              element: <InfluencerTracking />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
          ],
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <ClerkProviderWrapper pubKey={adminClerkPubKey}>
          <OrganizationUserProvider>
            <AdminPage />
          </OrganizationUserProvider>
          <SignedOut>
            <Layout body={<SignIn redirectUrl={`/admin`} />} />
          </SignedOut>
        </ClerkProviderWrapper>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/admin/organizations" replace />,
        },
        {
          path: "csv",
          element: <AdminCsvUpload />,
        },
        { path: "contacts/*", element: <AdminContacts /> },
        { path: "brands/*", element: <AdminBrands /> },
        {
          path: "organizations/*",
          element: <AdminOrganization />,
        },
        {
          path: "elasticsearch",
          element: <AdminElasticsearch />,
        },
        {
          path: "migrations",
          element: <AdminMigrations />,
        },
        {
          path: "emails",
          element: <AdminReadEmail />,
        },
        {
          path: "referrals",
          element: <AdminReferrals />,
        },
        {
          path: "scraping",
          element: <AdminScraping />,
        },
        {
          path: "user-requests",
          element: <UserRequests />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
