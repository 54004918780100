export const routes = {
  dashboard: "dashboard",
  onboarding: "onboarding",
  onboardingInfluencer: "start-onboarding",
  onboardingVaInfluencer: "va-onboarding",
  signIn: "sign-in",
  signUp: "sign-up",
  signUpVa: "sign-up/va",
  founder: "user",
  influencer: "start",
  tracking: "tracking",

  // VA Path
  aboutVaOnboarding: "va-onboarding/about",
  categoryVaOnboarding: "va-onboarding/category",
  recommendationVaOnboarding: "va-onboarding/recommendation",
  incomeOnboarding: "va-onboarding/income",
  vaIntroduction: "va-onboarding/va-introduction",
  vaPaidPortal: "va-onboarding/va-paid-portal",
  vaStepsToGetStarted: "va-onboarding/va-steps",
  vaFaq: "va-onboarding/va-faq",

  // Non-VA Path
  aboutOnboarding: "start-onboarding/about",
  categoryOnboarding: "start-onboarding/category",
  pitchOnboarding: "start-onboarding/pitch",
  currentPitchOnboarding: "start-onboarding/current-pitch",
  pitchingGoalOnboarding: "start-onboarding/pitching-goal",
  pitchingHighlightOnboarding: "start-onboarding/highlights",
  portfolioOnboarding: "start-onboarding/portfolio",
  partnershipsOnboarding: "start-onboarding/partnerships",
  engagementOnboarding: "start-onboarding/engagement",
  standOutOnboarding: "start-onboarding/stand-out",
  draftTemplateOnboarding: "start-onboarding/template",
  recommendationOnboarding: "start-onboarding/recommendation",
  selectBrandOnboarding: "start-onboarding/select-brand",
  emailOnboarding: "start-onboarding/email-brand",
  connectGmailOnboarding: "start-onboarding/connect",

  // remove
  introductionOnboarding: "start-onboarding/introduction",
  customizePartOneOnboarding: "start-onboarding/customize/1",
  customizePartTwoOnboarding: "start-onboarding/customize/2",

  // Dashboard
  inbox: "dashboard/inbox",
  brands: "dashboard/brands",
  opportunities: "dashboard/opportunities",
  settings: "dashboard/settings",
  profile: "dashboard/profile",
  templates: "dashboard/templates",
  pitchTrackerImport: "pitch-tracker/import",
  recommendations: "dashboard/recommendations",

  recommendationsSaved: "dashboard/recommendations/saved",
  recommendationsBento: "dashboard/recommendations/bento",

  // Tracking
  trackingView: "dashboard/tracking/view",

  // Templates
  create: "create",
  new: "new",
  import: "import",
  newTemplate: "new-template",

  // QuickSend
  outreachSend: "dashboard/outreach/send",
  discoverSend: "dashboard/brands/all/send",
};

export const isOnPage = (path: string) => {
  return window.location.href?.includes(path);
};

export type Route = {
  name: string;
  element: any;
};
