import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VAStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";

import Editor from "components/Editor";
import { useMergeTags } from "components/Editor/MergeTag/useMergeTags";
import { extensions } from "components/Editor/utils";
import Markdown from "components/Markdown";
import { MERGE_TAGS } from "constants/templates";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";

import DraftLoader from "../EmailBrandForm/DraftLoader";
import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface TemplateFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
}

const TemplateForm = ({
  onboardingForm,
  setOnboardingForm,
}: TemplateFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [draftTemplate, setDraftTemplate] = useState<Template | null>(null);
  const [keyImprovements, setKeyImprovements] = useState<string | null>(null);
  const [toggleMergeTagTooltip, toggleVariableTooltip] = useMergeTags();
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const subjectEditor = useEditor(
    {
      extensions: extensions({
        isPlain: true,
        suggestionOptions: MERGE_TAGS,
        placeholderText: "Collaborating with a Beauty Influencer",
      }),
      content: draftTemplate?.subject,
      onUpdate({ editor }) {
        setDraftTemplate((prevTemplate: Template | null) => {
          if (!prevTemplate) {
            return null;
          }
          return { ...prevTemplate, subject: editor.getHTML() };
        });
      },
    },
    [!!draftTemplate?.subject],
  ) as TipTapEditor;

  const draftTemplateEditor = useEditor(
    {
      extensions: extensions({
        toggleVariableTooltip,
        toggleMergeTagTooltip,
        suggestionOptions: MERGE_TAGS,
        placeholderText:
          "Hi there, I'm a fan of your brand's work in the industry, and I'm reaching out to see if you're interested in a collaboration...",
      }),
      content: draftTemplate?.body,
      onUpdate({ editor }) {
        setDraftTemplate((prevTemplate: Template | null) => {
          if (!prevTemplate) {
            return null;
          }
          return { ...prevTemplate, body: editor.getHTML() };
        });
      },
    },
    [!!draftTemplate?.body],
  ) as TipTapEditor;

  const signatureEditor = useEditor(
    {
      extensions: extensions({
        placeholderText: "Best, {Your Name}",
        hardBreaksOnly: true,
      }),
      content: draftTemplate?.signature,
      onUpdate({ editor }) {
        setDraftTemplate((prevTemplate: Template | null) => {
          if (!prevTemplate) {
            return null;
          }
          return { ...prevTemplate, signature: editor.getHTML() };
        });
        setOnboardingForm((prev) => {
          return { ...prev, signature: editor.getHTML() };
        });
      },
    },
    [!!draftTemplate?.signature],
  ) as TipTapEditor;

  const generateTemplate = async () => {
    if (!currentOrg) {
      return;
    }
    setLoading(true);
    try {
      const { subject, body, summary, signature } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/draft`,
        "POST",
        {},
        {},
      );
      if (!body) {
        setAlert(
          "Unable to generate template. Please reload and try again",
          "error",
        );
        return;
      }
      setDraftTemplate({
        name: "",
        subject,
        body,
        signature,
        contentType: TemplateContentType.OUTREACH,
      });
      setKeyImprovements(summary);
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (!currentOrg) {
      return;
    }
    setButtonLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/from-onboarding`,
        "POST",
        {},
        {
          ...draftTemplate,
        },
      );
      if (slideContext) {
        await slideContext.slideOutUp();
        slideContext.slideInUp();
      }
      const transferredFromVa =
        currentOrg?.vaStatus === VAStatus.not_interested &&
        onboardingForm?.wishlistBrands?.length > 0;
      navigate(
        `/${onboardingNavigate(location.pathname, onboardingForm, "next", transferredFromVa)}${
          location.search
        }`,
      );
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to save this template. Please reload and try again",
        "error",
      );
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    generateTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ mb: 3, textAlign: "center" }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-paper-plane"
        />
        How about this for a pitch?
      </Typography>

      {loading && (
        <Box sx={styles.templateWrapper}>
          <Box sx={styles.loaderWrapper}>
            <DraftLoader />
          </Box>
        </Box>
      )}
      {!loading && (
        <Grid
          container
          sx={styles.draftTemplateWrapper}
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid container item rowGap={1} xs={12} md={9}>
            <Grid item xs={12}>
              <FormHelperText sx={styles.helperText}>Subject</FormHelperText>
              <Editor editor={subjectEditor} hideMenu mergeTags={MERGE_TAGS} />
            </Grid>
            <Grid item xs={12}>
              <Editor
                editor={draftTemplateEditor}
                mergeTags={MERGE_TAGS}
                allowImage={true}
                allowAttachment={true}
                openAttachmentManager={() => {
                  setAttachmentDialogOpen(true);
                }}
                attachment={draftTemplate?.attachment}
                removeAttachment={() => {
                  setDraftTemplate((prevTemplate: Template | null) => {
                    if (!prevTemplate) return null;
                    return { ...prevTemplate, attachment: undefined };
                  });
                }}
              />
            </Grid>
            {draftTemplate?.signature && (
              <Grid item xs={12}>
                <FormHelperText sx={styles.helperText}>
                  Signature
                </FormHelperText>
                <Editor editor={signatureEditor} />
              </Grid>
            )}
            <AttachmentManager
              enteredSelection={(attachment: string) => {
                setDraftTemplate((prevTemplate: Template | null) => {
                  if (!prevTemplate) return null;
                  return { ...prevTemplate, attachment };
                });
              }}
              open={attachmentDialogOpen}
              setOpen={setAttachmentDialogOpen}
            />
            <Grid item xs={12}>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                Make any edits, then click 'Use Template' to save it for future
                use
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              gap={1}
              justifyContent="center"
              sx={{ mt: 1 }}
            >
              <Grid item xs="auto">
                <Button onClick={handleBack} color="secondary" disableElevation>
                  Back
                </Button>
              </Grid>
              <Grid item xs="auto">
                <LoadingButton
                  disableElevation
                  onClick={handleNext}
                  variant="contained"
                  loading={buttonLoading}
                >
                  Use Template
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              <strong>Key Improvements:</strong>
            </Typography>
            <Markdown content={keyImprovements || ""} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TemplateForm;
