import {
  Box,
  Button,
  Chip,
  Grid,
  Theme,
  Typography,
  lighten,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BENTO_BRAND_CATEGORIES } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { CustomEvent } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate, vaOnboardingNavigate } from "../helper";

interface Props {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
  isVaOnboarding?: boolean;
}

const styles = {
  hover: (theme: Theme) => ({
    "&:hover": {
      color: "white",
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  }),
  selected: {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
};
const EXEMPT_CATEGORIES = ["other_beauty_personal_care"];

const CategoryForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
  isVaOnboarding = false,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slideContext = useContext(SlideAnimationContext);

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    if (isVaOnboarding) {
      navigate(
        `/${vaOnboardingNavigate(location.pathname, "back")}${location.search}`,
      );
    } else {
      navigate(
        `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
          location.search
        }`,
      );
    }
  };

  const getCategoriesToPickFrom = () => {
    let categories: { label: string; key: string }[] = [];
    for (const category of BENTO_BRAND_CATEGORIES) {
      if (!category.subCategories || category.subCategories?.length === 0) {
        categories.push(category.mainCategory);
      } else {
        categories = [...categories, ...(category.subCategories || [])];
      }
    }
    return categories?.filter((x) => !EXEMPT_CATEGORIES?.includes(x.key));
  };

  const categoriesToPickFrom = getCategoriesToPickFrom();

  const selectChip = (label: string) => {
    const copy = makeDeepCopy(onboardingForm);
    const index = copy.categories?.findIndex((x: string) => x === label);
    if (index > -1) {
      copy.categories.splice(index, 1);
    } else {
      if (!copy.categories) copy.categories = [];
      copy.categories.push(label);
    }
    setOnboardingForm(copy);
  };

  const isSelected = (label: string) => {
    if (onboardingForm?.categories?.includes(label)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ textAlign: "center", mt: isProfilePage ? 2 : 0 }}
        >
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-layer-group"
          />
          Your Brand Types
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: "center", mb: 3 }}
        >
          What are your preferred types of brands to work with? *
        </Typography>
        <Grid container justifyContent="center" sx={{ my: 2 }} gap={2}>
          {categoriesToPickFrom?.map((chip, index) => (
            <Chip
              key={index}
              sx={[styles.hover, isSelected(chip.label) && styles.selected]}
              label={chip.label}
              onClick={() => selectChip(chip.label)}
            />
          ))}
        </Grid>
        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} color="secondary" disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <Button
                disableElevation
                variant="contained"
                type="submit"
                disabled={
                  !onboardingForm?.categories ||
                  onboardingForm?.categories?.length === 0
                }
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CategoryForm;
