import { useAuth } from "@clerk/clerk-react";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { camelCase, capitalize } from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";
import { OrganizationProfile } from "schemas/dashboard";
import { StringMap } from "schemas/functions";

import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { replaceSubjectMergeTag } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/helpers";
import { BentoTemplate } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";

import { fetcherAuth } from "./api";
import { titleCase } from "./string";
import { makeDeepCopy } from "./updateLocalState";

const _TAGS_TO_ICONS: StringMap = {
  past_partnerships: "🤝",
  engagement: "📈",
  case_study: "✨📷✨",
  portfolio: "💼",
};

const _TAGS_TO_TITLE: StringMap = {
  past_partnerships: "your past partnerships",
  engagement: "your engagement",
  case_study: "case study",
  portfolio: "your portfolio",
};

const _ORGANIZATION_PROFILE_FIELD_TO_TEMPLATE_VARIABLE = {
  introduction: "about_me",
  signature: "signature",
  brands: "past_partnerships",
  most_impressive_partnerships: "past_partnerships",
  engagement: "engagement",
  num_of_followers: "followers",
  most_engaged_post: "post_example",
  case_study: "case_study",
};

export default function useTemplate() {
  const { currentOrg, profile, setProfile } = useContext(
    OrganizationUserContext,
  );
  const { getToken } = useAuth();
  const { templates } = useContext(OutreachTemplatesContext);

  const getTemplateName = (bentoTemplate: BentoTemplate, index: number) => {
    const keyTags = [];
    const titles: string[] = [];

    for (const tag of bentoTemplate?.tags) {
      if (tag in _TAGS_TO_TITLE) {
        titles.push(_TAGS_TO_TITLE[tag]);
        keyTags.push(tag);
      }
    }

    let icon = "🙌";
    if (titles?.length === 1 && keyTags[0] in _TAGS_TO_ICONS) {
      icon = _TAGS_TO_ICONS[keyTags[0]];
    }

    let finalizedTitle = `${capitalize(bentoTemplate?.contentType)} ${
      index + 1
    } - ${capitalize(bentoTemplate?.tags?.[0])}`;
    const isOutreach =
      bentoTemplate?.contentType === TemplateContentType.OUTREACH;
    if (isOutreach) {
      if (titles?.length === 0 && bentoTemplate?.subject) {
        finalizedTitle = replaceSubjectMergeTag(bentoTemplate?.subject || "");
      } else if (titles?.length === 1) {
        finalizedTitle = `${icon} Highlight ${titles[0]}`;
      } else if (titles?.length > 1) {
        const finalTag = titles.pop();
        finalizedTitle = `${icon} Highlight ${titles?.join(
          ", ",
        )} & ${finalTag}`;
      }
      return `Outreach ${index + 1}: ${finalizedTitle}`;
    } else {
      return finalizedTitle;
    }
  };

  const getUnfilledVariables = (body: string) => {
    const unfilledVariables: string[] = [];
    if (body?.includes(`data-subtype="user-specific"`)) {
      for (const variable of Object.values(
        _ORGANIZATION_PROFILE_FIELD_TO_TEMPLATE_VARIABLE,
      )) {
        if (body?.includes(titleCase(variable))) {
          unfilledVariables.push(titleCase(variable));
        }
      }
    }
    if (body?.includes(`data-id="most_engaged_post"`)) {
      unfilledVariables.push("Post Example");
    }
    return unfilledVariables;
  };

  const updateTemplateBody = (
    setTemplateForQuickSend: Dispatch<SetStateAction<Template[]>>,
    body: string,
    index: number,
    contactName?: string,
  ) => {
    setTemplateForQuickSend((prev) => {
      const copyQuickSend = [...prev];
      copyQuickSend[index]["body"] = body;
      return copyQuickSend;
    });
  };

  const updateTemplateWithProfileVariables = async (
    templateIdToUpdate: number,
    profile: OrganizationProfile,
    setTemplateForQuickSend: Dispatch<SetStateAction<Template[]>>,
  ) => {
    // If user decided to fill out a template variable before sending outreach
    // we want to update their template (but ignore variables that are dependent on the brand).
    const template = templates?.find(
      (template) => template.id === templateIdToUpdate,
    );
    if (!template || !template.body) return "";

    let body = makeDeepCopy(template.body);

    const paragraphs = template.body.match(/<span.*?<\/span>/g) || [];
    for (const line of paragraphs) {
      for (const variable in _ORGANIZATION_PROFILE_FIELD_TO_TEMPLATE_VARIABLE) {
        if (line?.includes(`data-id="${variable}`)) {
          const key = camelCase(variable as keyof OrganizationProfile);
          const profileValue = profile[key as keyof OrganizationProfile];
          if (profileValue) {
            body = body.replaceAll(line, profileValue);
          }
        }
      }
    }

    setTemplateForQuickSend((prev) => {
      const copy = makeDeepCopy(prev);
      if (copy) {
        copy[0]["body"] = body;
      }
      return copy;
    });

    await fetcherAuth(
      getToken,
      `/api/organization/${currentOrg?.id}/templates/${template.id}`,
      "PUT",
      {},
      {
        body,
      },
    );
    filledInOrgProfile();
  };

  const filledInOrgProfile = async () => {
    try {
      if (profile) {
        const profileRes = await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/settings`,
          "PUT",
          {},
          { ...profile },
        );
        setProfile(profileRes.organizationProfile);
      }
    } catch (error) {
      // Failed silently, since user can just refill these values again
    }
  };

  const replaceMergeTagWithVariable = (
    body: string,
    firstName: string,
    brandName: string,
  ) => {
    return body
      .replace(/<span[^>]*[^>]*>{First Name}<\/span>/g, firstName)
      .replace(/<span[^>]*[^>]*>{Brand Name}<\/span>/g, brandName)
      .replace(/{First Name}/g, firstName)
      .replace(/{Brand Name}/g, brandName);
  };

  return {
    getTemplateName,
    getUnfilledVariables,
    updateTemplateBody,
    updateTemplateWithProfileVariables,
    filledInOrgProfile,
    replaceMergeTagWithVariable,
  };
}
