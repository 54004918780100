import { SubscriptionType } from "./dashboard";

export enum Plans {
  FREE = "free",
  STARTER = "starter",
  PRO = "pro",
  VA = "va",
  PRO_YEARLY = "pro_yearly",
  STARTER_YEARLY = "starter_yearly",
  VA_YEARLY = "va_yearly",
}

export enum OverridePlanName {
  PRO = "pro",
  STARTER = "starter",
  VA = "va",
}

export const SubscriptionMessages: { [key: string]: string } = {
  [SubscriptionType.CANCELED]:
    "Your subscription has been cancelled. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.PAUSED]:
    "Your subscription has been paused. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.INCOMPLETE_EXPIRED]:
    "Your payment was incomplete when subscribing to Bento. Please subscribe to continue using Bento.",
  [SubscriptionType.INCOMPLETE]:
    "Your payment was incomplete when subscribing to Bento. Please subscribe to continue using Bento.",
  [SubscriptionType.PAST_DUE]:
    "Your latest payment for the subscription failed. Please update your subscription to continue being able to use Bento.",
  [SubscriptionType.UNPAID]:
    "Your latest payment for the subscription failed. Please update your subscription to continue being able to use Bento.",
};

export const getEquivalentPlansFromOverride = (
  overridePlanName: OverridePlanName,
) => {
  const plans: { [key: string]: Plans[] } = {
    [OverridePlanName.PRO]: [Plans.PRO, Plans.PRO_YEARLY],
    [OverridePlanName.STARTER]: [Plans.STARTER, Plans.STARTER_YEARLY],
    [OverridePlanName.VA]: [Plans.VA, Plans.VA_YEARLY],
  };
  return overridePlanName in plans ? plans[overridePlanName] : [];
};

export const DisplayName: { [key in Plans]: string } = {
  [Plans.FREE]: "Free",
  [Plans.STARTER]: "Starter",
  [Plans.PRO]: "Pro",
  [Plans.VA]: "Virtual Assistant",
  [Plans.PRO_YEARLY]: "Pro Yearly",
  [Plans.STARTER_YEARLY]: "Starter Yearly",
  [Plans.VA_YEARLY]: "Virtual Assistant Yearly",
};
