import { IncomeLevel } from "schemas/dashboard";
import { Map } from "schemas/functions";

export const getIncomeLevel = (onboardingForm: Map) => {
  if (
    !onboardingForm?.monthlyIncome ||
    !(onboardingForm?.monthlyIncome?.length === 2)
  ) {
    return "";
  }

  const minIncome = onboardingForm?.monthlyIncome[0];
  const maxIncome = onboardingForm?.monthlyIncome[1];

  if (minIncome === 0 && maxIncome === 0) return IncomeLevel.starting;
  if (minIncome === 0 && maxIncome <= 500) return IncomeLevel.gettingFeetWet;
  if (minIncome >= 500 && maxIncome <= 2000) return IncomeLevel.gettingHangOfIt;
  if (maxIncome >= 2000) return IncomeLevel.seasoned;
};
