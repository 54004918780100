import { Box, Button, Grid, Typography } from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { TemplateGoal } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface PitchingGoalProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
}

const PitchingGoal = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
}: PitchingGoalProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const selectGoal = (templateGoal: TemplateGoal) => {
    const onboardingFormCopy = makeDeepCopy(onboardingForm);
    onboardingFormCopy.defaultGoal = templateGoal;
    setOnboardingForm(onboardingFormCopy);
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <form onSubmit={handleNext}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-dollar-sign"
        />
        What is your current pitching goal?
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 3, textAlign: "center" }}>
        Gifted partnerships are good for content creators that are new to
        working with brands
      </Typography>

      <Grid container justifyContent="center" gap={1}>
        <Grid item xs="auto">
          <Button
            sx={[
              styles.pitchButton,
              onboardingForm.defaultGoal === TemplateGoal.PAID &&
                styles.selectedPitchButton,
            ]}
            variant="outlined"
            onClick={() => selectGoal(TemplateGoal.PAID)}
            disableElevation
          >
            <Box
              sx={styles.pitchIcon}
              component="i"
              className="fa-solid fa-money-check-dollar"
            />
            Paid Partnerships
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            sx={[
              styles.pitchButton,
              onboardingForm.defaultGoal === TemplateGoal.GIFT &&
                styles.selectedPitchButton,
            ]}
            variant="outlined"
            onClick={() => selectGoal(TemplateGoal.GIFT)}
            disableElevation
          >
            <Box
              sx={styles.pitchIcon}
              component="i"
              className="fa-solid fa-gift"
            />
            Gifted Partnerships
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start" sx={{ mt: 2 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button
            disableElevation
            type="submit"
            variant="contained"
            disabled={
              onboardingForm.defaultGoal !== TemplateGoal.GIFT &&
              onboardingForm.defaultGoal !== TemplateGoal.PAID
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PitchingGoal;
