import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { SetAlertType } from "schemas/functions";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "utils/api";

import styles from "./styles";

interface ViewScoreDialogProps {
  open: boolean;
  handleClose: () => void;
  setAlert: SetAlertType;
}

export default function ViewScoreDialog({
  open,
  handleClose,
  setAlert,
}: ViewScoreDialogProps) {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { plans } = useContext(SubscriptionContext);

  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    if (!currentOrg) {
      return;
    }
    setLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/checkout`,
        "POST",
        {},
        {
          plan: Plans.PRO,
          source: "View Score Dialog",
        },
      );
      window.open(res.session.url, "_self");
    } catch (error) {
      setAlert(
        <>
          Unable to create checkout session. Please{" "}
          <Link
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            refresh the page
          </Link>{" "}
          and try again. Contact hello@onbento.com for assistance if the problem
          persists
        </>,
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton sx={styles.closeButton} onClick={handleClose}>
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center" }}>
        This is a feature for Pro Plan Users 🔐
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        Unlock exclusive access to brands more likely to respond with Bento's
        "Reply Score" feature. Bento assigns a rating to brands and contact
        persons based on whether they have previously responded to a creator on
        the platform.
        {plans && (
          <Typography sx={{ mt: 2 }} variant="h6">
            <strong>${plans[Plans.PRO]?.price}/month</strong>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center" sx={{ mb: 2 }}>
          <LoadingButton
            disableElevation
            variant="contained"
            loading={loading}
            onClick={handleCheckout}
          >
            Subscribe
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
