import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface StandOutFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  isProfilePage?: boolean;
}

const StandOutForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  isProfilePage = false,
}: StandOutFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const handleSkip = async () => {
    if (slideContext) {
      await slideContext.slideOutUp();
      slideContext.slideInUp();
    }
    trackEvent("Onboarding Form - Skip Button Pressed", {
      page: "standOutForm",
    });

    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "next")}${
        location.search
      }`,
    );
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  return (
    <form onSubmit={handleNext}>
      {!isProfilePage && (
        <>
          <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
            <Box
              component="i"
              color="secondary.dark"
              sx={{ pr: 2 }}
              className="fa-solid fa-hashtag"
            />
            What stands out about you?
          </Typography>

          <Typography style={{ marginBottom: "16px" }}>
            Write a couple of bullet points about what makes you stand out as a
            creator! (e.g. your former career, the type of content you
            specialize in, unique skills you have, etc.)
          </Typography>
        </>
      )}
      {isProfilePage && (
        <FormHelperText sx={styles.helperText}>
          Many creators promise high-quality content, but what truly makes you
          stand out?
        </FormHelperText>
      )}
      <Grid sx={styles.fieldContainer}>
        <TextField
          sx={styles.textField}
          fullWidth
          name="standsOutDescription"
          multiline
          minRows={2}
          onChange={handleChange}
          value={onboardingForm?.standsOutDescription || ""}
        />
      </Grid>
      {!isProfilePage && (
        <Grid
          container
          justifyContent="flex-start"
          columnGap={1}
          sx={{ mt: 2 }}
        >
          <Grid item xs="auto">
            <Button onClick={handleBack} color="secondary" disableElevation>
              Back
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Button onClick={handleSkip} color="secondary" disableElevation>
              Skip
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button disableElevation type="submit" variant="contained">
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default StandOutForm;
