import { useAuth } from "@clerk/clerk-react";
import lodash from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Email,
  EmailActivity,
  GmailThread,
  OutreachContact,
  OutreachContactStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

export const PER_PAGE = 25;

export type Suggestion = {
  id: number;
  type: "outreach_contact" | "brand";
  suggestion: string;
};

export enum Order {
  asc = "asc",
  desc = "desc",
}

export enum OrderBy {
  brand_name = "brand_name",
  contact = "contact",
  status = "status",
  is_important = "is_important",
  last_gmail_message_date = "last_gmail_message_date",
}

export enum InboxSidebarOptionKey {
  sent = "sent",
  inbox = "inbox",
  scheduled = "scheduled",
}

export enum ScheduledType {
  outreach = "outreach",
  followUp = "follow-up",
}

export type InboxSidebarOption = {
  key: InboxSidebarOptionKey;
  label: string;
  icon: string;
  statuses: OutreachContactStatus[];
};

export type SortOption = {
  order: Order;
  orderBy: OrderBy;
  name: string;
};

export const InboxSidebarStatusMap: {
  [key in InboxSidebarOptionKey]: OutreachContactStatus[];
} = {
  [InboxSidebarOptionKey.inbox]: [
    OutreachContactStatus.bounced,
    OutreachContactStatus.received_response,
    OutreachContactStatus.interested,
    OutreachContactStatus.not_interested,
  ],
  [InboxSidebarOptionKey.sent]: [
    OutreachContactStatus.no_response,
    OutreachContactStatus.bounced,
    OutreachContactStatus.received_response,
    OutreachContactStatus.interested,
    OutreachContactStatus.not_interested,
  ],
  [InboxSidebarOptionKey.scheduled]: [OutreachContactStatus.pending],
};

export const InboxSidebarOptions: InboxSidebarOption[] = [
  {
    key: InboxSidebarOptionKey.inbox,
    label: "Inbox",
    icon: "fa-solid fa-inbox",
    statuses: InboxSidebarStatusMap.inbox,
  },
  {
    key: InboxSidebarOptionKey.sent,
    label: "Sent",
    icon: "fa-solid fa-paper-plane",
    statuses: InboxSidebarStatusMap.sent,
  },
  {
    key: InboxSidebarOptionKey.scheduled,
    label: "Scheduled",
    icon: "fa-regular fa-clock",
    statuses: InboxSidebarStatusMap.scheduled,
  },
];

export const defaultFetchContactsParams = {
  page: 1,
  statuses: InboxSidebarStatusMap.inbox,
  selectedSuggestion: null,
  order: Order.desc,
  orderBy: OrderBy.last_gmail_message_date,
  scheduledType: null,
};

type FetchContactsParams = {
  page: number;
  selectedSuggestion: Suggestion | null;
  statuses: OutreachContactStatus[];
  importantOnly?: boolean;
  order?: Order;
  orderBy?: OrderBy;
  scheduledType: ScheduledType | null;
};

interface OutreachContactsContextInterface {
  fetchLoading: boolean;
  setFetchLoading: Dispatch<SetStateAction<boolean>>;
  contacts: OutreachContact[];
  total: number | null;
  setContacts: Dispatch<SetStateAction<OutreachContact[]>>;
  currentContact: OutreachContact | null;
  setCurrentContact: Dispatch<SetStateAction<OutreachContact | null>>;
  currentContactEmailThreads: GmailThread[];
  setCurrentContactEmailThreads: Dispatch<SetStateAction<GmailThread[]>>;
  currentContactScheduledEmails: Email[];
  setCurrentContactScheduledEmails: Dispatch<SetStateAction<Email[]>>;
  currentContactEmailActivities: EmailActivity[];
  currentContactThreadsLoading: boolean;
  currentContactLoading: boolean;
  currentContactScheduledEmailsLoading: boolean;
  currentContactEmailActivitiesLoading: boolean;
  setCurrentContactLoading: Dispatch<SetStateAction<boolean>>;
  currentContactPageToken: string | null;
  fetchContact: (outreachContactId: number) => void;
  fetchEmailThreads: (outreachContactId: number, reset?: boolean) => void;
  fetchScheduledEmails: (outreachContactId: number) => void;
  fetchEmailActivities: (outreachContactId: number) => void;
  abortController: React.MutableRefObject<AbortController | undefined>;
  fetchContactsParams: FetchContactsParams;
  currentSidebarOption: InboxSidebarOption | undefined;
  hitsLimit: boolean;
  fetchMoreLoading: boolean;
  missingIntegration: boolean;
  isFiltering: boolean;
  getSortOptions: (sidebarOptionKey?: InboxSidebarOptionKey) => SortOption[];
  handleFetchContacts: (updatedParams?: FetchContactsParams) => void;
  fetchTotals: () => void;
  totals: { [key in InboxSidebarOptionKey]: number | null };
  scheduledTotals: { [key in ScheduledType]: number | null };
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  fetchLoading: false,
  setFetchLoading: defaultContextMissingFunction,
  contacts: [],
  total: null,
  setContacts: defaultContextMissingFunction,
  currentContact: null,
  setCurrentContact: defaultContextMissingFunction,
  currentContactEmailThreads: [],
  setCurrentContactEmailThreads: defaultContextMissingFunction,
  currentContactScheduledEmails: [],
  currentContactEmailActivities: [],
  setCurrentContactScheduledEmails: defaultContextMissingFunction,
  currentContactThreadsLoading: true,
  currentContactLoading: true,
  currentContactScheduledEmailsLoading: true,
  currentContactEmailActivitiesLoading: true,
  setCurrentContactLoading: defaultContextMissingFunction,
  currentContactPageToken: null,
  fetchContact: defaultContextMissingFunction,
  fetchEmailThreads: defaultContextMissingFunction,
  fetchScheduledEmails: defaultContextMissingFunction,
  fetchEmailActivities: defaultContextMissingFunction,
  abortController: undefined,
  fetchContactsParams: defaultFetchContactsParams,
  currentSidebarOption: undefined,
  hitsLimit: false,
  fetchMoreLoading: false,
  missingIntegration: false,
  isFiltering: false,
  getSortOptions: defaultContextMissingFunction,
  handleFetchContacts: defaultContextMissingFunction,
  fetchTotals: defaultContextMissingFunction,
  totals: {
    [InboxSidebarOptionKey.inbox]: null,
    [InboxSidebarOptionKey.sent]: null,
    [InboxSidebarOptionKey.scheduled]: null,
  },
  scheduledTotals: {
    [ScheduledType.outreach]: null,
    [ScheduledType.followUp]: null,
  },
};

const OutreachContactsContext =
  // @ts-ignore: We cannot do `useRef` to initialize `abortController`
  createContext<OutreachContactsContextInterface>(defaultInterface);

interface OutreachContactsProviderProps {
  children: React.ReactNode;
}

const OutreachContactsProvider = ({
  children,
}: OutreachContactsProviderProps) => {
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();

  // Outreach Page State
  const [totals, setTotals] = useState<{
    [key in InboxSidebarOptionKey]: number | null;
  }>({
    [InboxSidebarOptionKey.inbox]: null,
    [InboxSidebarOptionKey.sent]: null,
    [InboxSidebarOptionKey.scheduled]: null,
  });
  const [scheduledTotals, setScheduledTotals] = useState<{
    [key in ScheduledType]: number | null;
  }>({
    [ScheduledType.followUp]: null,
    [ScheduledType.outreach]: null,
  });
  const [contacts, setContacts] = useState<OutreachContact[]>([]);
  const [fetchContactsParams, setFetchContactsParams] =
    useState<FetchContactsParams>(defaultFetchContactsParams);
  const [total, setTotal] = useState<number | null>(null);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [missingIntegration, setMissingIntegration] = useState(false);

  // Individual Outreach Contact State
  const [currentContact, setCurrentContact] = useState<OutreachContact | null>(
    null,
  );
  const [currentContactEmailThreads, setCurrentContactEmailThreads] = useState<
    GmailThread[]
  >([]);
  const [currentContactPageToken, setCurrentContactPageToken] = useState<
    string | null
  >(null);
  const [currentContactThreadsLoading, setCurrentContactThreadsLoading] =
    useState<boolean>(true);
  const [currentContactLoading, setCurrentContactLoading] =
    useState<boolean>(true);
  const [currentContactScheduledEmails, setCurrentContactScheduledEmails] =
    useState<Email[]>([]);
  const [currentContactEmailActivities, setCurrentContactEmailActivities] =
    useState<EmailActivity[]>([]);
  const [
    currentContactEmailActivitiesLoading,
    setCurrentContactEmailActivitiesLoading,
  ] = useState<boolean>(true);
  const [
    currentContactScheduledEmailsLoading,
    setCurrentContactScheduledEmailsLoading,
  ] = useState<boolean>(true);

  const abortController = useRef<AbortController | undefined>(undefined);

  const fetchContacts = async (updatedParams: FetchContactsParams) => {
    if (updatedParams.page === 1) {
      setFetchLoading(true);
      setContacts([]);
    } else {
      setFetchMoreLoading(true);
    }
    if (!currentOrg?.id) {
      return;
    }
    let url;
    if (
      updatedParams.statuses.length === 1 &&
      updatedParams.statuses[0] === OutreachContactStatus.pending
    ) {
      url = `/api/organization/${currentOrg?.id}/outreach-contacts?include=brand,bento_brand,emails&per_page=${PER_PAGE}&page=${updatedParams.page}`;
    } else {
      url = `/api/organization/${currentOrg?.id}/outreach-contacts?include=brand,bento_brand&per_page=${PER_PAGE}&page=${updatedParams.page}`;
    }

    if (updatedParams.selectedSuggestion) {
      if (updatedParams.selectedSuggestion.type === "brand") {
        url += `&brand_id=${updatedParams.selectedSuggestion.id}`;
      } else {
        url += `&outreach_contact_id=${updatedParams.selectedSuggestion.id}`;
      }
    }
    if (updatedParams.statuses.length > 0) {
      url +=
        "&" +
        updatedParams.statuses.map((status) => `statuses=${status}`).join("&");
    }
    if (updatedParams.importantOnly) {
      url += `&is_important=${updatedParams.importantOnly}`;
    }
    if (updatedParams.orderBy) {
      url += `&sort=${updatedParams.orderBy}:${updatedParams.order}`;
    }
    if (updatedParams.scheduledType) {
      url += `&scheduled_type=${updatedParams.scheduledType}`;
    }
    try {
      abortController.current = new AbortController();
      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current.signal,
      );
      if (updatedParams.page === 1) {
        setContacts(res.outreachContacts);
      } else {
        setContacts((prev) => [...prev, ...res.outreachContacts]);
      }
      setTotal(res.total);
      if (
        !updatedParams.importantOnly &&
        updatedParams.selectedSuggestion === null
      ) {
        const sideBarOptionKey =
          InboxSidebarOptions.find((option) =>
            lodash.isEqual(option.statuses, updatedParams.statuses),
          )?.key || InboxSidebarOptionKey.inbox;
        if (
          sideBarOptionKey !== InboxSidebarOptionKey.scheduled &&
          updatedParams.scheduledType
        ) {
          setTotals((prev) => ({ ...prev, [sideBarOptionKey]: res.total }));
        } else {
          setScheduledTotals((prev) => {
            const copy = { ...prev };
            if (updatedParams.scheduledType) {
              copy[updatedParams.scheduledType] = res.total;
              if (
                copy[ScheduledType.followUp] !== null &&
                copy[ScheduledType.outreach] !== null
              ) {
                const newScheduledTotal =
                  copy[ScheduledType.followUp] + copy[ScheduledType.outreach];
                setTotals((prev) => ({
                  ...prev,
                  [InboxSidebarOptionKey.scheduled]: newScheduledTotal,
                }));
              }
            }
            return copy;
          });
        }
      }
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Unable to fetch contacts. Please reload and retry again",
        "error",
      );
    } finally {
      setFetchLoading(false);
      setFetchMoreLoading(false);
    }
  };

  const fetchEmailThreads = async (
    outreachContactId: number,
    reset: boolean = false,
  ) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactThreadsLoading(true);

    let pageToken = currentContactPageToken;

    if (reset) {
      setCurrentContactPageToken(null);
      setCurrentContactEmailThreads([]);
      pageToken = null;
    }

    let url = `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/threads`;
    if (pageToken) {
      url += `?page_token=${pageToken}`;
    }
    try {
      const { gmailThreads, nextPageToken } = await fetcherAuth(
        getToken,
        url,
        "GET",
      );
      setCurrentContactEmailThreads((prev) => [...prev, ...gmailThreads]);
      setCurrentContactPageToken(nextPageToken);
      setMissingIntegration(false);
    } catch (error) {
      if (error?.message?.includes("Google integration")) {
        setMissingIntegration(true);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setCurrentContactThreadsLoading(false);
    }
  };

  const fetchTotals = async () => {
    if (!currentOrg) {
      return;
    }

    try {
      const {
        inboxTotal,
        sentTotal,
        scheduledFollowUpTotal,
        scheduledOutreachTotal,
      } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/totals`,
        "GET",
      );
      setTotals({
        [InboxSidebarOptionKey.inbox]: inboxTotal,
        [InboxSidebarOptionKey.sent]: sentTotal,
        [InboxSidebarOptionKey.scheduled]:
          scheduledFollowUpTotal + scheduledOutreachTotal,
      });
      setScheduledTotals({
        [ScheduledType.followUp]: scheduledFollowUpTotal,
        [ScheduledType.outreach]: scheduledOutreachTotal,
      });
    } catch (error) {}
  };

  const fetchContact = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactLoading(true);

    try {
      const { outreachContact } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}`,
        "GET",
      );
      setCurrentContact(outreachContact);
      // update the contact itself if it is in the list
      setContacts((prev) => {
        const copy = makeDeepCopy(prev);
        for (const idx in copy) {
          if (copy[idx].id === outreachContact.id) {
            copy[idx] = outreachContact;
          }
        }
        return copy;
      });
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactLoading(false);
    }
  };

  const fetchScheduledEmails = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactScheduledEmailsLoading(true);

    try {
      const { scheduledEmails } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/scheduled-emails`,
        "GET",
      );
      setCurrentContactScheduledEmails(scheduledEmails);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactScheduledEmailsLoading(false);
    }
  };

  const fetchEmailActivities = async (outreachContactId: number) => {
    if (!currentOrg) {
      return;
    }
    setCurrentContactEmailActivitiesLoading(true);

    try {
      const { lastEmailActivities } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/last-email-activity`,
        "GET",
      );
      setCurrentContactEmailActivities(lastEmailActivities);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setCurrentContactEmailActivitiesLoading(false);
    }
  };

  const handleFetchContacts = (updatedParams?: FetchContactsParams) => {
    if (updatedParams) {
      setFetchContactsParams(updatedParams);
    }
    const params = updatedParams || fetchContactsParams;
    fetchContacts(params);
  };

  const currentSidebarOption = useMemo(() => {
    return InboxSidebarOptions.find((option) =>
      lodash.isEqual(option.statuses, fetchContactsParams.statuses),
    );
  }, [fetchContactsParams.statuses]);

  const hitsLimit = total === null ? false : total <= contacts.length;

  const isFiltering =
    fetchContactsParams.importantOnly ||
    !!fetchContactsParams.selectedSuggestion;

  const getSortOptions = (sideBarOptionKey?: InboxSidebarOptionKey) => {
    const sorts = [
      {
        order: Order.desc,
        orderBy: OrderBy.last_gmail_message_date,
        name: "Most Recent First",
      },
      {
        order: Order.asc,
        orderBy: OrderBy.last_gmail_message_date,
        name: "Oldest First",
      },
      {
        order: Order.desc,
        orderBy: OrderBy.status,
        name: "Most Important Status",
      },
      {
        order: Order.asc,
        orderBy: OrderBy.brand_name,
        name: "Brand Name: A to Z",
      },
      {
        order: Order.desc,
        orderBy: OrderBy.brand_name,
        name: "Brand Name: Z to A",
      },
      {
        order: Order.asc,
        orderBy: OrderBy.contact,
        name: "Email: A to Z",
      },
      {
        order: Order.desc,
        orderBy: OrderBy.contact,
        name: "Email: Z to A",
      },
    ];
    if (sideBarOptionKey === InboxSidebarOptionKey.scheduled) {
      sorts[0].order = Order.asc;
      sorts[1].order = Order.desc;
    }
    return sorts;
  };

  return (
    <OutreachContactsContext.Provider
      value={{
        fetchLoading,
        setFetchLoading,
        contacts,
        total,
        setContacts,
        currentContact,
        setCurrentContact,
        currentContactEmailThreads,
        setCurrentContactEmailThreads,
        currentContactEmailActivities,
        currentContactScheduledEmails,
        setCurrentContactScheduledEmails,
        currentContactLoading,
        setCurrentContactLoading,
        currentContactThreadsLoading,
        currentContactScheduledEmailsLoading,
        currentContactEmailActivitiesLoading,
        currentContactPageToken,
        fetchContact,
        fetchEmailThreads,
        fetchScheduledEmails,
        fetchEmailActivities,
        abortController,
        fetchContactsParams,
        currentSidebarOption,
        hitsLimit,
        fetchMoreLoading,
        missingIntegration,
        isFiltering,
        getSortOptions,
        handleFetchContacts,
        fetchTotals,
        totals,
        scheduledTotals,
      }}
    >
      {children}
    </OutreachContactsContext.Provider>
  );
};

export { OutreachContactsContext, OutreachContactsProvider };
